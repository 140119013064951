import React from "react";

import { useAppContext } from "../../context";
import { actions } from "../../context/actions";
import { IProduct } from "../../types";
import { ModalTypes } from "../Modals/types";
import { useParams } from "react-router-dom";

export const usePopularFilters = ({ products }: { products: IProduct[] }) => {
  const [isPriceFilter, setIsPriceFilter] = React.useState(false);
  const [isBrandsFilter, setIsBrandsFilter] = React.useState(false);
  const [isCategoriesFilter, setIsCategoriesFilter] = React.useState(false);
  const [isSpecialOffersFilter, setIsSpecialOffersFilter] = React.useState(false);
  const [isCustomFilter, setIsCustomFilter] = React.useState<string[]>([]);

  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();
  const { termId } = useParams();

  const priceFilter = searchFilters.find(
    (filter: any) => filter.name === "Price"
  );
  const variantsFilter = searchFilters.find(
    (filter: any) => filter.name === 'Variants'
  );
  const ratingFilter = searchFilters.find(
    (filter: any) => filter.name === 'Rating'
  );

  const handlePriceRangeContext = () => {
    const productsCopy = [...products];
    const priceSortProducts = productsCopy.sort(
      (
        { Price: aPrice, RegularPrice: aRegularPrice },
        { Price: bPrice, RegularPrice: bRegularPrice }
      ) => {
        const priceA = aRegularPrice ? aRegularPrice : aPrice;
        const priceb = bRegularPrice ? bRegularPrice : bPrice;
        return priceb - priceA;
      }
    );
    const productDatabaseMinPrice = priceSortProducts[products.length - 1]
      .RegularPrice
      ? priceSortProducts[products.length - 1].RegularPrice
      : priceSortProducts[products.length - 1].Price;

    const productDatabaseMaxPrice = priceSortProducts[0].RegularPrice
      ? priceSortProducts[0].RegularPrice
      : priceSortProducts[0].Price;

    !priceFilter?.userSelectedPriceFilterMin &&
      !priceFilter?.userSelectedPriceFilterMax &&
      dispatch({
        type: actions.ADD_SEARCH_FILTER,
        payload: {
          name: "Price",
          productDatabaseMinPrice,
          productDatabaseMaxPrice,
        },
      });
  };

  const getCustomizedFilterData = (filterElement: any) => {
    let filterData = { key: filterElement?.Name, clickHandler: () => { }, colors: '' }
    switch (filterElement?.Name) {
      case 'Brand':
        filterData.colors = isBrandsFilter ? 'dark' : 'light';
        filterData.clickHandler = () => {
          dispatch({
            type: actions.TOGGLE_MODAL,
            payload: {
              isModal: true,
              modalType: ModalTypes.brands,
              modalContent: termId,
            },
          });
        }
        break;
      case 'Price':
        filterData.colors = isPriceFilter ? 'dark' : 'light';
        filterData.clickHandler = () => {
          handlePriceRangeContext();
          dispatch({
            type: actions.TOGGLE_MODAL,
            payload: {
              isModal: true,
              modalType: ModalTypes.priceRange,
            },
          });
        };
        break;
      case 'Categories':
        filterData.colors = isCategoriesFilter ? 'dark' : 'light';
        filterData.clickHandler = () => {
          dispatch({
            type: actions.TOGGLE_MODAL,
            payload: {
              isModal: true,
              modalType: ModalTypes.categories,
              modalContent: { products },
            },
          });
        }
        break;
      case 'Rating':
        filterData.colors = ratingFilter?.value ? 'dark' : 'light';
        filterData.clickHandler = () => {
          dispatch({
            type: actions.TOGGLE_MODAL,
            payload: {
              isModal: true,
              modalType: ModalTypes.rating,
            },
          });
        };
        break;
      case 'Special Offers':
        filterData.colors = isSpecialOffersFilter ? 'dark' : 'light';
        filterData.clickHandler = () => {
          dispatch({
            type: actions.TOGGLE_MODAL,
            payload: {
              isModal: true,
              modalType: ModalTypes.specialOffers,
              modalContent: { products },
            },
          });
        };
        break;
      case 'Variants':
        filterData.colors = variantsFilter?.value ? 'dark' : 'light';
        filterData.clickHandler = () => {
          dispatch({
            type: actions.TOGGLE_MODAL,
            payload: {
              isModal: true,
              modalType: ModalTypes.variants,
            },
          });
        };
        break;
      default:
        filterData.key = 'Custom_' + filterElement.Name;
        filterData.colors = (isCustomFilter.includes('Custom_' + filterElement.Name) ? 'dark' : 'light');
        filterData.clickHandler = () => {
          dispatch({
            type: actions.ADD_SEARCH_FILTER,
            payload: {
              name: 'Custom_' + filterElement.Name,
              value: filterElement.Name,
              checked: isCustomFilter.includes('Custom_' + filterElement.Name) ? false : true,
            },
          });
        }

        break;
    }
    return filterData;
  };

  React.useEffect(() => {
    if (
      priceFilter?.userSelectedPriceFilterMin ||
      priceFilter?.userSelectedPriceFilterMax
    ) {
      setIsPriceFilter(true);
    } else {
      setIsPriceFilter(false);
    }
  }, [searchFilters, priceFilter]);

  React.useEffect(() => {
    const brandsFilter = searchFilters.find(
      (filter: any) => filter.name === "Brand"
    );
    const brands =
      brandsFilter && brandsFilter.brands && Object.values(brandsFilter.brands);
    const isBrands = brands && brands.some((brand: any) => brand === true);
    setIsBrandsFilter(isBrands);
  }, [searchFilters]);

  React.useEffect(() => {
    if (searchFilters?.length < 1) setIsCustomFilter([]);
    const customFilter = searchFilters.findLast(
      (filter: any) => filter.name.includes("Custom_") // === 'Custom_Pampers'
    );
    const CustomFilter =
      customFilter && customFilter.name; //&& Object.values(customFilter.value);
    if (CustomFilter != undefined && !isCustomFilter.includes(CustomFilter)) {
      setIsCustomFilter(isCustomFilter => [...isCustomFilter!, CustomFilter]);
    } else if (isCustomFilter.includes(CustomFilter)) {
      let removeCustomFilter = isCustomFilter.filter((Filter: string) => Filter != CustomFilter)
      setIsCustomFilter(removeCustomFilter);
    }
  }, [searchFilters]);

  React.useEffect(() => {
    const categoriesFilter = searchFilters.find(
      (filter: any) => filter.name === 'Categories'
    );
    const categories =
      categoriesFilter && categoriesFilter.categories && Object.values(categoriesFilter.categories);
    const isCategory = categories && categories.some((categories: any) => categories === true);
    setIsCategoriesFilter(isCategory);
  }, [searchFilters]);

  React.useEffect(() => {
    const specialOffersFilter = searchFilters.find(
      (filter: any) => filter.name === 'Special Offers'
    );
    const promo =
      specialOffersFilter && specialOffersFilter.promo && Object.values(specialOffersFilter.promo);
    const isPromo = promo && promo.some((promo: any) => promo === true);
    setIsSpecialOffersFilter(isPromo);
  }, [searchFilters]);

  //on filters component first load initialize price range minimum and maximum within searchFilters store
  return {
    isPriceFilter,
    isBrandsFilter,
    isCustomFilter,
    isCategoriesFilter,
    isSpecialOffersFilter,
    ratingFilter,
    variantsFilter,
    handlePriceRangeContext,
    getCustomizedFilterData,
  };
};
