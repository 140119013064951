import React from "react";

import { useNavigate } from "react-router-dom";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { HeaderStyled } from "../../styles";

import styled from "@emotion/styled";
import { useLocalization } from "../../../../hooks/useLocalization";

export const CartHeader = () => {
  const navigate = useNavigate();
  const { translation } = useLocalization();
  return (
    <HeaderStyled>
      <CartHeaderStyled>
        <FontAwesomeIcon
          className="chevron-left"
          icon={faChevronLeft}
          color="#fff"
          onClick={() => navigate(-1)}
        />
        <span>{translation?.getLocalization?.Cart || "Cart"}</span>
      </CartHeaderStyled>
    </HeaderStyled>
  );
};

const CartHeaderStyled = styled.div`
  text-align: center;
  position: relative;
  padding: 27px 0 10px;
  min-height: 67px;

  .chevron-left {
    position: absolute;
    left: 25px;
    font-size: 2.2rem;
  }
  span {
    color: #fff;
    font-size: 1.8rem;
    font-weight: 600;
  }
`;
