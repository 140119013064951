import React from "react";
import styled from "@emotion/styled";
import { Button, ButtonVariants } from "../Button";
import { useLocalization } from "../../hooks/useLocalization";

export const EmptyCard = ({checkoutMessage}:any) => {
  const { translation } = useLocalization();
  const emptyCartDescription = translation?.getLocalization?.AllOfWalmart || "All of Walmart is at your fingertips - start shopping!"
  return (
    <EmptyCardStyled>
      <img src="/files/walmart/walmart-empty-cart.svg" alt="empty-cart" />
      {checkoutMessage !== "" ?
        <>
          <p>{translation?.getLocalization?.CheckoutMessage || "Checkout finished"}</p>
          <p>{emptyCartDescription}</p>
        </>
        :
        <>
          <p>{translation?.getLocalization?.YourCartIsEmpty || "Your cart is empty"}</p>
          <p>{emptyCartDescription}</p>
        </>
      }
      <div className="btn">
        <Button
          variant={ButtonVariants.blueGeneric}
          onClick={() => {}}
          content={translation?.getLocalization?.StartShopping || "Start shopping"}
        />
      </div>
    </EmptyCardStyled>
  );
};

const EmptyCardStyled = styled.div`
  text-align: center;
  img {
    margin-top: 15%;
    height: 150px;
  }
  p:first-of-type {
    font-weight: bolder;
    font-size: 1.4rem;
    margin-top: 20px;
  }
  p:last-of-type {
    font-weight: 300;
    font-size: 1.25rem;
    margin-top: 10px;
  }
  .btn {
    margin-top: 15%;
    display: flex;
    justify-content: center;
    button {
      font-size: 1.15rem;
      padding: 10px 20px;
    }
  }
`;
