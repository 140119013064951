import React from "react";

import { useAppContext } from "../../context";
import { actions } from "../../context/actions";
import { IProduct } from "../../types";

export const useFilters = ({ products }: { products: IProduct[] }) => {
  const [isPriceFilter, setIsPriceFilter] = React.useState(false);
  const [isBrandsFilter, setIsBrandsFilter] = React.useState(false);

  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();

  const priceFilter = searchFilters.find(
    (filter: any) => filter.name === "Price"
  );

  const handlePriceRangeContext = () => {
    const productsCopy = [...products];
    const priceSortProducts = productsCopy.sort(
      (
        { Price: aPrice, RegularPrice: aRegularPrice },
        { Price: bPrice, RegularPrice: bRegularPrice }
      ) => {
        const priceA = aRegularPrice ? aRegularPrice : aPrice;
        const priceb = bRegularPrice ? bRegularPrice : bPrice;
        return priceb - priceA;
      }
    );
    const productDatabaseMinPrice = priceSortProducts[products.length - 1]
      .RegularPrice
      ? priceSortProducts[products.length - 1].RegularPrice
      : priceSortProducts[products.length - 1].Price;

    const productDatabaseMaxPrice = priceSortProducts[0].RegularPrice
      ? priceSortProducts[0].RegularPrice
      : priceSortProducts[0].Price;

    !priceFilter?.userSelectedPriceFilterMin &&
      !priceFilter?.userSelectedPriceFilterMax &&
      dispatch({
        type: actions.ADD_SEARCH_FILTER,
        payload: {
          name: "Price",
          productDatabaseMinPrice,
          productDatabaseMaxPrice,
        },
      });
  };

  React.useEffect(() => {
    if (
      priceFilter?.userSelectedPriceFilterMin ||
      priceFilter?.userSelectedPriceFilterMax
    ) {
      setIsPriceFilter(true);
    } else {
      setIsPriceFilter(false);
    }
  }, [searchFilters, priceFilter]);

  React.useEffect(() => {
    const brandsFilter = searchFilters.find(
      (filter: any) => filter.name === "Brand"
    );
    const brands =
      brandsFilter && brandsFilter.brands && Object.values(brandsFilter.brands);
    const isBrands = brands && brands.some((brand: any) => brand === true);
    setIsBrandsFilter(isBrands);
  }, [searchFilters]);

  //on filters component first load initialize price range minimum and maximum within searchFilters store
  return {
    isPriceFilter,
    isBrandsFilter,
    handlePriceRangeContext,
  };
};
