import React from "react";

import { AddToCart } from "./variants/AddToCart";
import { BlueGenericButton } from "./variants/BlueGeneric";
import { TransparentButton } from "./variants/Transparent";

export enum ButtonVariants {
  add = "add",
  addTransparent = "addTransparent",
  blueGeneric = "blueGeneric",
}

interface Ibutton {
  variant?: ButtonVariants | string;
  onClick: any;
  content?: any;
  url?: any;
}

export const Button: React.FC<Ibutton> = ({ variant, onClick, content,url }) => {
  switch (variant as any) {
    case ButtonVariants.add:
      return <AddToCart onClick={onClick} url={url} content={content} />;
    case ButtonVariants.addTransparent:
      return (
        <AddToCart onClick={onClick} url={url} content={content} styles="transparent" />
      );
    case ButtonVariants.blueGeneric:
      return <BlueGenericButton onClick={onClick} content={content} />;
    default:
      return <TransparentButton content={content} onClick={onClick} />;
  }
};
