import styled from "@emotion/styled";

export const PopularFiltersStyled = styled.div`
  padding: 16px 0;
  position: relative;

  & > div {
    overflow-x: scroll;
    white-space: nowrap;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 15px;

    &:first-of-type{
      padding-top:20px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &.sortFilter {
      justify-content: center;
      padding-top: 15px;
    }
    scrollbar-width: none;
  }
`;

export const ChipsContainer = styled.div`
  overflow-x: auto;
  display: flex;
  gap: 16px;
  white-space: nowrap;
  padding: 15px 0;
  & > span:first-of-type {
    margin-left: auto;
  }
  & > span:last-of-type {
    margin-right: auto;
  }
`;
