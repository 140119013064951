import React from 'react';
import styled from '@emotion/styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { IProduct } from '../../../../../../types';
import { imgSrcByEnv } from '../../../../../../../config/imgSrcByEnv';

export const PdpGallery = ({ product }: { product: IProduct }) => {
  const gallery = product?.Gallery;
  const video = product?.VideoGallery;
  let arr = [""];

  gallery && gallery.map(({ image: { fullpath } }) => {
    arr.push(fullpath);
  });
  video && video.map(({ Video: { data, poster }, VideoPosition }) => {
    arr.splice(Number(VideoPosition), 0, data.fullpath + '^^^' + poster.fullpath);
  });
  arr.shift();


  return (
    <GalleryStyled numberOfBullets={arr.length}>
      <div className="big-image-container">
        <Swiper
          loop={true}
          modules={[Pagination, Navigation]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={true}
        >
          {arr && arr.map((elem) => {
            let videoPaths = elem.split('^^^');
            return (
              <SwiperSlide key={elem}>
                {!elem.includes("Videos") ?
                  <img
                    className="big-image"
                    src={`${imgSrcByEnv()}${elem}`}
                    alt={"product packshot"}
                  />
                  :
                  <video
                    className="big-image"
                    src={`${imgSrcByEnv()}${videoPaths[0]}`}
                    controls autoPlay muted loop
                  />
                }
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </GalleryStyled>
  );
};

const GalleryStyled = styled.div<{
  numberOfBullets: number;
}>`
  grid-column: 1 / span 2;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 20px 0;

  .big-image-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .big-image {
      width: 100%;
      max-height: calc(100vh - 80px);
      object-fit: contain;
    }
    .swiper-slide{
      align-self:center;
    }
    .swiper-pagination {
      position: static;
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 10px 0;
      min-width: 60px;

      .swiper-pagination-bullet {
        border: 1px solid #babbbe !important;
        border-radius: 0px !important;
        width: ${({ numberOfBullets }) => `${170 / numberOfBullets}px`};
        padding: 0px !important;
        margin: 0px !important;
        box-shadow: none !important;
        height: 3px;
      }

      .swiper-pagination-bullet-active {
        background: #000;
        border: 1px solid #000 !important;
        transform: scaleX(1.2);
      }
    }
    .swiper-button-next, .swiper-button-prev{
      color: #202020;
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
`;
