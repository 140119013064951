import React from "react";

import { IProduct } from "../../../../types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { FilterModalCommonStyles } from "../../commonStyles";
import { FilterModalButtons } from "../../../FilterModalButtons";
import { BrandsStyled } from "./styles";
import { useBrandsFilter } from "./useBrandsFilter";

export const BrandsFilterModal = ({ termId }: { termId: string }) => {
  const {
    searchBar,
    setSearchBarInput,
    handleSubmit,
    handleClear,
    handleCheckboxes,
    products,
    checked,
    translation
  } = useBrandsFilter({ termId });
  return (
    <FilterModalCommonStyles>
      <BrandsStyled>
        <div>
          <h2>{translation?.getLocalization?.BrandsAreYouInterestedIn || "What brands are you interested in?"}</h2>

          <div className="search-bar">
            <FontAwesomeIcon icon={faSearch} />
            <input
              value={searchBar}
              onChange={(e) => setSearchBarInput(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faTimesCircle}
              onClick={() => setSearchBarInput("")}
            />
          </div>

          <div className="checkboxes">
            <FormGroup>
              {products ? (
                products
                  .filter(
                    (product: IProduct, index: number, arr: IProduct[]) =>
                      product.Brand?.toLowerCase().includes(
                        searchBar.toLowerCase()
                      ) &&
                      index ===
                        arr.findIndex((item) => item.Brand === product.Brand)
                  )
                  .map((product: IProduct, index: number) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          name={product.Brand}
                          checked={checked[product.Brand]}
                          onChange={handleCheckboxes}
                        />
                      }
                      label={product.Brand}
                    />
                  ))
              ) : (
                <div></div>
              )}
            </FormGroup>
          </div>
        </div>

        <FilterModalButtons onSubmit={handleSubmit} onClear={handleClear} />
      </BrandsStyled>
    </FilterModalCommonStyles>
  );
};
