import React from "react";

import { createIcon } from "../../helpers";

import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";

import { faStar as faStarEmpty } from "@fortawesome/fontawesome-free-regular";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styled from "@emotion/styled";

export const Ratings = ({
  rating,
  fontSize,
}: {
  rating: number;
  fontSize?: string;
}) => {
  const ratings = createIcon({
    rating,
    icons: { complete: faStar, half: faStarHalfAlt, empty: faStarEmpty },
    max: 5,
  });

  return (
    <RatingsStyled fontSize={fontSize}>
      {ratings.map((icon, i) => {
        return (
          <FontAwesomeIcon
            key={i}
            icon={
              typeof icon.name === "string"
                ? (icon.name as IconProp)
                : icon.name
            }
            className="icon"
          />
        );
      })}
    </RatingsStyled>
  );
};

interface Iprops {
  fontSize?: string;
}

const RatingsStyled = styled.div<Iprops>`
  .icon {
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "1rem")};
    margin-right: 2px;
  }
`;
