import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";
import { actions } from "../../context/actions";
import { useAppContext } from "../../context";
import { ModalTypes } from "../Modals/types";
import { Chip } from "../Chip";
import { PopularFiltersStyled } from "./styles";
import { useParams } from "react-router-dom";
import { IProduct } from "../../types";
import { usePopularFilters } from "./usePopularFilters";
import { PillFiltersComponent } from "./PillFilters";
import { PillFiltersWithImageComponent } from "./PillFiltersWithImage";
import { useConfiguration } from "../../hooks/useConfigurationHook";
import ResetFiltersButton from "../ResetFiltersButton/ResetFiltersButton";
import { useLocalization } from "../../hooks/useLocalization";

export const PopularFilters = ({
  products,
  GenericFilters,
  PillFiltersWithImage,
  PillFilters,
}: {
  products: IProduct[];
  GenericFilters?: string[];
  PillFiltersWithImage?: any[];
  PillFilters?: any[];
}) => {
  const {
    value: { productsSorting },
    dispatch,
  } = useAppContext();
  const { getCustomizedFilterData } = usePopularFilters({ products, });
  const { storeId = '', termId, harvestId = '' } = useParams();
  const { FilterType, SortByEnable } = useConfiguration({ storeId, harvestId });
  const { translation } = useLocalization();
  const sortFilterIsPlacedOnTop = (SortByEnable !== false && FilterType === 'Image');

  return (
    <PopularFiltersStyled>
      {FilterType && <ResetFiltersButton dispatch={dispatch} />}
      {(FilterType === 'Plain' || FilterType === 'All') &&
        <PillFiltersComponent filters={PillFilters} sortFilterEnabled={!sortFilterIsPlacedOnTop} getCustomizedFilterData={getCustomizedFilterData} />
      }
      {(FilterType === 'Image' || FilterType === 'All') &&
        <PillFiltersWithImageComponent filters={PillFiltersWithImage} getCustomizedFilterData={getCustomizedFilterData} />
      }
      {sortFilterIsPlacedOnTop &&
        <div className="sortFilter">
          <Chip
            title={translation?.getLocalization?.SortAndFilter || "Sort and Filter"}
            addinitonalIcon={<FontAwesomeIcon icon={faSortAmountDown} />}
            onClick={() => {
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.sortAndFilter,
                },
              });
            }}
            isExpand={true}
            colors={productsSorting.length ? "dark" : "light"}
          />
        </div>
      }
    </PopularFiltersStyled>
  );
};
