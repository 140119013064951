import styled from "@emotion/styled";

interface IProps {
  isBottomBorder: boolean;
}

export const CustomLabelStyledDiv = styled.div<{
  LabelColor?: string;
  LabelTextColor?: string;
  LabelPosition: string;
}>`
  font-weight: bold;
  margin: 3%;
  padding: 5px;
  border: 1px solid ${({LabelColor}) => 
  LabelColor != '' ? LabelColor : '' };
  border-radius: 3px;
  position: absolute;
  top: 0;
  background-color: ${({LabelColor}) => LabelColor != '' ? LabelColor : '' };
  ${({ LabelPosition }) => LabelPosition === 'Left' ? 'left: 0;' : 'right: 0;'}
  width: max-content;
  color: ${({LabelTextColor}) => LabelTextColor != '' ? LabelTextColor : '' };
`
export const ProductStyled = styled.div<IProps>`
  width: 100%;

  @media (min-width: 550px) {
    width: 23%;
  }

  border-bottom: ${({ isBottomBorder }) =>
    isBottomBorder ? "1px solid #f2f2f2" : "none"};
  padding-bottom: 10px;
  .top {
    position: relative;
    button {
      position: absolute;
      bottom: 10px;
    }
    img {
      height: 200px;
      width: 100%;
      object-fit: contain;
      display: block;
      margin: 0 auto;
    }
    .sponsored {
      color: #666666;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .sponsored {
    color: #666666;
    margin: 0 3%;
    text-align: right;
  }
  .sponsored {
    color: #666666;
    margin: 0 3%;
    text-align: right;
  }
  .price {
    font-size: 1.6rem;
    font-weight: 700;
    margin-top: 10px;
    .oldPrice {
      margin-left: 5px;
      font-size: 13px;
      color: #999999;
      text-decoration: line-through
    }
  }
  .promo{
    height: auto;
    & > img {
      width: 100%;
    }
    & > p {
      font-size: 13px;
      font-weight: 450;
      line-height: 24px;
    }
  }
  .name {
    margin-top: 12px;
    color: #6e6f70;
    line-height: 1.2;
    font-size: 1.4rem;
    height: 35px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .ratings {
    display: flex;
    align-items: center;
    margin-top: 10px;
    span {
      margin-left: 5px;
      color: #6e6f70;
    }
  }
  .save {
    margin-top: 10px;
    max-width: 80px;
  }
  .labels {
    display:flex;
    flex-direction:row;
    gap:3px;
    margin-top: 10px;
    @media (min-width:550px){
      flex-direction:column;
    }
  }
`;
