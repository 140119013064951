import styled from "@emotion/styled";

const sectionsMargin = "15px";

export const AccountRouteStyled = styled.div`
  .content {
    position: relative;
    padding-top: 30px;
    .background {
      top: 0;
      position: absolute;
      height: 60px;
      width: 100%;
      background-color: #0071dc;
    }
  }
  .purchase-history,
  .subscriptions {
    position: relative;
    z-index: 1;
    padding: 20px;
    box-shadow: 3px 3px 10px 2px rgba(207, 207, 207, 0.75);
    -webkit-box-shadow: 3px 3px 10px 2px rgba(207, 207, 207, 0.75);
    -moz-box-shadow: 3px 3px 10px 2px rgba(207, 207, 207, 0.75);
    border-radius: 7px;
    background: #fff;
    margin: 0 ${sectionsMargin};
  }

  .purchase-history {
    & > div:first-of-type {
      display: flex;
      align-items: center;
      span {
        margin-left: 20px;
        font-size: 1.6rem;
        font-weight: 600;
      }
      svg {
        font-size: 2.2rem;
      }
    }
    & > div:last-of-type {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 1.5rem;
        font-weight: 500;
      }
      button {
        padding: 7px 17px;
      }
    }
  }
  .subscriptions {
    margin-top: 20px;
    overflow: hidden;
    & > div:first-of-type {
      font-size: 1.55rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        font-size: 1.7rem;
      }
    }
  }
  .your-thoughts {
    background-color: #f3f7fc;
    text-align: center;
    margin-top: 9%;
    padding: 7% 0 6%;
    p {
      font-size: 1.5rem;
    }
    button {
      padding: 10px 25px;
      font-size: 1.6rem;
      margin-top: 6%;
      display: inline-block;
    }
  }
  .list {
    li {
      margin-top: 9%;
      font-size: 1.35rem;
    }
    list-style-type: none;
    margin: 0 ${sectionsMargin};
  }
  .rights-reserved {
    margin: 15px ${sectionsMargin};
  }
`;
