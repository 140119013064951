import { Instagram as ContentLoader } from 'react-content-loader';
import { Header, HeaderVariants } from '../../components/Header';
import { Products } from '../../components/Products';
import { ShopLocation } from '../../components/ShopLocation';
import { ErrorPage } from '../../components/ErrorPage';
import { Categories } from '../../components/Categories';
import { Filters } from '../../components/Filters';
import { useCategoryNameHook } from '../../hooks/useCategoryNameHook';
import { ProductHeader } from '../../components/Products/styles';
import { SquareFilters } from '../../components/SquareFilters';
import { IProduct } from '../../types';
import { useSearchTermRoute } from './useSearchTermRoute';
import { TermBanner } from '../../components/TermBanner';
import { PopularFilters } from '../../components/PopularFilters';

export const SearchTermRoute = () => {
  const { name } = useCategoryNameHook();

  const {
    handleProducts,
    handleBrandsFilter,
    activatedFilters,
    loading,
    data,
    translation
  } = useSearchTermRoute();

  if (loading) {
    return <ContentLoader />;
  } else if (!loading && !data.getEPogTermListing.edges[0]) {
    return (
      <>
        <Header variant={HeaderVariants.search} />
        <ShopLocation
          shopName="Secaucus Supercenter"
          shopLocation="Bronx 10451"
        />
        <ErrorPage />
      </>
    );
  } else if (!loading && data.getEPogTermListing.edges[0]) {
    const {
      getEPogTermListing: { edges },
    } = data;

    let node: any = edges[0].node;

    const products = node.Products;
    const categories = node.children;
    const brandFilters = node.BrandFilters;
    const GenericFilters =
      node.GenericFilters &&
      node.GenericFilters.map((filter: any) => filter.BrandName);

    const { TermBannerText, TermBannerSponsoredBy, TermBannerProducts, TermBannerVideoPosition } = node;
    const TermBannerImage = node.TermBannerImage?.fullpath;
    const TermBannerVideo = node?.TermBannerVideo?.data?.fullpath;

    const notClickableBrands =
      (brandFilters &&
        Array.from(
          new Set(brandFilters.map((brandFilter: any) => brandFilter.BrandName))
        )) ||
      [];

    const clickableBrands =
      (products &&
        Array.from(
          new Set(products.map((product: IProduct) => product.Brand))
        )) ||
      [];

    const addProductButton = node.AddProductButton;
    const optionsButton = node.OptionsButton;

    const productsToRender = products && handleProducts(products);

    const baseFilters = () => {

    }

    return (
      <>
        <Header variant={HeaderVariants.search} />
        <ShopLocation
          shopName="Secaucus Supercenter"
          shopLocation="Bronx 10451"
        />
        {TermBannerProducts && (
          <TermBanner
            text={TermBannerText}
            sponsoredBy={TermBannerSponsoredBy}
            img={TermBannerImage}
            products={TermBannerProducts}
            addProductButton={addProductButton}
            optionsButton={optionsButton}
            TermBannerVideo={TermBannerVideo}
            TermBannerVideoPosition={TermBannerVideoPosition}
          />
        )}
        {(node?.PillFilters?.length > 0 || node?.PillFiltersWithImage?.length > 0) ?
          (products && (
            <PopularFilters products={products} GenericFilters={GenericFilters} PillFilters={node?.PillFilters} PillFiltersWithImage={node?.PillFiltersWithImage} />
          ))
          : <>
            {(notClickableBrands || clickableBrands) && (
              <SquareFilters
                notClickableBrands={notClickableBrands}
                clickableBrands={clickableBrands}
                onFilterClick={handleBrandsFilter}
                activatedFilters={activatedFilters}
              />
            )}
            {products && (
              <Filters products={products} GenericFilters={GenericFilters} />
            )}
          </>
        }
        {products && (
          <ProductHeader>
            {translation?.getLocalization?.ResultsFor} "{name}"<span>({productsToRender.length})</span>
          </ProductHeader>
        )}
        {categories && <Categories categories={categories} />}
        {products && (
          <Products
            products={productsToRender}
            addProductButton={addProductButton}
            optionsButton={optionsButton}
            searchEngineResultsPage={true}
            promoBannerData={{
              text:TermBannerText,
              sponsoredBy:TermBannerSponsoredBy,
              img : TermBannerImage,
              products : TermBannerProducts,
              addProductButton : addProductButton,
              optionsButton : optionsButton
            }} />
        )}
      </>
    );
  } else {
    return <div></div>;
  }
};
