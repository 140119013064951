import { gql } from '@apollo/client';

export const PRODUCT_FRAGMENT = gql`
  fragment Product on object_ePog {
    fullpath
    Attribute {
      ... on fieldcollection_Attributes {
        AttrName
        AttrValue
      }
    }
    CustomFilterValues {
      ... on fieldcollection_AdditionalFilter{
        AdditionalFilterValue
      }
    }
    Brand
    Name
    PromoImage {
      fullpath
    }
    Video {
      data {
        ...on asset {
          fullpath
        }
      }
      poster {
        fullpath
      }
    }
    VideoGalleryPosition
    VideoGallery {
      ...on fieldcollection_VideoGallery {
        Video{
          data {
            ...on asset {
              fullpath
            }
          }
          poster {
            fullpath
          }
        }
        VideoPosition
      }
    }
    PromoText
    PromoType
    LabelColorCode
    LabelPosition
    LabelText
    LabelTextColor
    Sponsored
    URLPromoCode
    Description
    Gallery {
      image {
        fullpath
      }
    }
    key
    Nutritient
    Featured
    Price
    RRAmount
    RROverall
    RegularPrice
    PromoAmount
    PromoCount
    Short
    Tags
    id
    SubCategory
    Category {
      ... on fieldcollection_Categories {
        CategoryPath
      }
    }
  }
`;

export const CATEGORY_FRAGMENT = gql`
  fragment Category on object_ePogTerm {
    key
    id
    fullpath
  }

  fragment RecursiveCategory on object_ePogTerm {
    ...Category
    children {
      ...Category
      ... on object_ePogTerm {
        children {
          ...Category
          ... on object_ePogTerm {
            children {
              ...Category
              ... on object_ePogTerm {
                children {
                  ...Category
                  ... on object_ePogTerm {
                    children {
                      ...Category
                      ... on object_ePogTerm {
                        children {
                          ...Category
                          ... on object_ePogTerm {
                            children {
                              ...Category
                              ... on object_ePogTerm {
                                children {
                                  ...Category
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
