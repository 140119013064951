import React from "react";

import styled from "@emotion/styled";

export const StoreTools = () => {
  return (
    <StoreToolsStyled>
      <div className="title">
        <p>Store Tools</p>
        <div>
          <u>Find a store nearby</u>
        </div>
      </div>
      <div className="tools">
        <StoreTool imgSrc="/files/walmart/walmart-pay.png" text="Walmart Pay" />
        <StoreTool
          imgSrc="/files/walmart/check-price.png"
          text="Check a price"
        />
      </div>
    </StoreToolsStyled>
  );
};

const StoreToolsStyled = styled.div`
  margin: 15px 0;
  .title {
    p {
      margin-top: 20px;
      font-size: 1.65rem;
      font-weight: 600;
    }
    div {
      padding-top: 15px;
      font-size: 1.3rem;
      font-weight: 300;
    }
  }
  .tools {
    display: flex;
    gap: 15px;
  }
`;

const StoreTool = ({ text, imgSrc }: { text: string; imgSrc: string }) => {
  return (
    <StoreToolStyled>
      <img src={imgSrc} alt={text} />
      <p>{text}</p>
    </StoreToolStyled>
  );
};

const StoreToolStyled = styled.div`
  gap: 16px;
  text-align: center;
  padding: 18px 14px;
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  border-bottom-width: 2px;
  width: 50%;
  img {
    width: 85px;
  }
  p {
    font-size: 1.4rem;
    margin-top: 5px;
    line-height: 1.5;
    font-weight: 500;
  }
`;
