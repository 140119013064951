export enum ModalTypes {
  sortAndFilter,
  productOverview,
  pickupAndShipping,
  priceRange,
  brands,
  deliveryDate,
  categories,
  rating,
  variants,
  specialOffers,
}
