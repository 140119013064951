import styled from "@emotion/styled";

export const FooterStyles = styled.footer`
  position: fixed;
  bottom: 0;
  z-index: 1;
  display: block;
  width: 100%;
  border-top: 1px solid #cacbce;
  height: 6%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #fff;
  padding: 1%;
  a {
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const MenuLink = styled.button<{ isActive: boolean }>`
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  background: transparent;
  border: none;
  color: ${({ isActive }) => (isActive ? "#0271dc" : "#76787e")};
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  img {
    height: 25px;
    margin-bottom: 2px;
  }
`;
