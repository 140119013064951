import React from 'react';

import { ProductsStyled } from './styles';
import { Product } from '../Product';
import { IProduct } from '../../types';
import { useAppContext } from '../../context';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfigurationHook';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { ItermBanner, TermBanner } from '../TermBanner';

export const Products = ({
  products,
  addProductButton,
  optionsButton,
  searchEngineResultsPage,
  promoBannerData,
}: {
  products: IProduct[];
  addProductButton: string;
  optionsButton: string;
  searchEngineResultsPage?:boolean;
  promoBannerData?: ItermBanner;
}) => {
  const {
    value: { isModal },
  } = useAppContext();

  const urlParams = useParams();
  const {pageNr, storeId='', harvestId=''} = urlParams;

  const { Columns, Rows } = useConfiguration({
    storeId,
    harvestId
  });
  let itemsPerPage = Columns === 3 ? 9 : 16;
  if (Rows && Columns) {
    itemsPerPage = Columns * Rows;
  }
  const currentPage = Number(pageNr) || 1;
  return (
    <ProductsStyled isModal={isModal}>
      <div className="products-list">
        {products.map((product: any, index: number) => {
          const isPromoBanner = product?.Video?.title === '__promoBannerPlaceholder__';
          let itemIndex = index;
          if (isPromoBanner && !products[index - 1]?.fullpath?.includes("Products")) // prev video
              itemIndex = index - 1;
          if (isPromoBanner && !products[index + 1]?.fullpath?.includes("Products")) // next video
            itemIndex = index + 1;
          const position = itemsPerPage * (currentPage - 1) + itemIndex + 1;
          let newColumns = 4;
          let cssClass = '';
          if (Columns !== undefined) { newColumns = Columns; }
          if (position === 1 || position % newColumns === 1) { cssClass = 'fullsize'; }
          if (isPromoBanner) {
            return promoBannerData?.products && <TermBanner {...promoBannerData} />
          } else if (!product?.fullpath?.includes("Products")) {
            return (
              <div className={'video '+cssClass}>
                <video src={`${imgSrcByEnv()}${product?.Video?.data.fullpath}`} controls autoPlay muted loop />
              </div>
            )
          } else {
            return (
              <Product
                key={index}
                position={index + 1}
                product={product}
                addProductButton={addProductButton}
                optionsButton={optionsButton}
                urlParams={urlParams}
                searchEngineResultsPage={searchEngineResultsPage}
              />)
          }
        })}
      </div>
    </ProductsStyled>
  );
};
