import styled from "@emotion/styled";

export const fontColor = "#97999d";

export const SearchInputStyled = styled.div<{ isSearchMenu?: boolean }>`
  background: #fff;
  padding: 7px 13px 7px 19px;
  border-radius: 20px;
  height: 100%;
  width: ${({ isSearchMenu }) => (!isSearchMenu ? "77%" : "88%")};
  display: flex;
  align-items: center;
  form {
    width: 100%;
  }
  svg:first-of-type {
    font-size: 15px;
  }

  svg:last-of-type {
    font-size: 23px;
  }

  input {
    margin-left: 2%;
    border: none;
    height: 100%;
    width: 89%;
    outline: none;
    font-size: 1.6rem;
    color: ${fontColor};
  }
`;
