import styled from "@emotion/styled";
import React from "react";
import { CartIconButton } from "../../../CartIconButton";
import { SearchInput } from "../../../SearchInput";
import { HeaderStyled } from "../../styles";
import { useHeader } from "../../useHeader";
import { useLocalization } from "../../../../hooks/useLocalization";

export const HomeHeader = () => {
  const { handleCartIconClick, handleNavigateToSearchMenu } = useHeader();
  const { translation } = useLocalization();

  return (
    <HeaderStyled>
      <HomeHeaderStyled>
        <div>
          <span>{translation?.getLocalization?.SignIn || "Sign in"}</span>
          <img src="/files/walmart/walmartLogo.svg" alt="walmart-logo" />
          <CartIconButton onClick={handleCartIconClick} />
        </div>
        <div onClick={handleNavigateToSearchMenu}>
          <SearchInput />
        </div>
      </HomeHeaderStyled>
    </HeaderStyled>
  );
};

const HomeHeaderStyled = styled.div`
  padding: 15px 30px 6px 8px;
  color: #fff;
  & > div:first-of-type {
    display: flex;
    justify-content: space-between;
    & > span {
      margin-top: 10px;
      font-size: 1.6rem;
    }
    img {
      width: 30px;
    }
  }
  & > div:last-of-type {
    margin-top: 6px;
    & > div {
      width: 100%;
    }
  }
`;
