import React from 'react';

import { ContextProvider } from './context';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes';

import styled from '@emotion/styled';

import { ScrollToTop } from './routes/ScrollToTopRouteChange';

const WALMART_MOBILE = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />

      <ContextProvider>
        <AppStyled>
          <AppRoutes />
        </AppStyled>
      </ContextProvider>
    </BrowserRouter>
  );
};

export default WALMART_MOBILE;

const AppStyled = styled.div`
  position: relative;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 7%;
  * {
    -webkit-tap-highlight-color: transparent;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }
`;
