import { useAppContext } from "../context";
import { actions } from "../context/actions";

export const useTransitionDimmer = () => {
  const { dispatch } = useAppContext();

  const toggleDimmer = () =>
    dispatch({
      type: actions.TOGGLE_DIMMER,
      payload: { isDimmer: true },
    });
  return { toggleDimmer };
};
