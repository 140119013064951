import React from 'react';

import styled from '@emotion/styled';
import { IProduct } from '../../types';
import { useProduct } from '../Product/useProduct';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { useLocalization } from '../../hooks/useLocalization';

export const VariantsSlider = ({
  currentProduct,
  variants,
}: {
  currentProduct: IProduct;
  variants: IProduct[];
}) => {
  const { translation } = useLocalization();
  const unique: IProduct[] = [currentProduct, ...variants].reduce(
    (acc: any, item) => {
      if (!acc.some((e: any) => e.id === item.id)) {
        acc.push(item);
      }
      return acc;
    },
    []
  );

  return (
    <VariantsStyled>
      <p>{translation?.getLocalization?.ProductVariants || "Product variants"}:</p>
      <div>
        {unique
          ?.filter((v, i, a) => a.indexOf(v) === i)
          ?.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
          ?.map((variant, index) => (
            <Variant
              variant={variant}
              key={index}
              currentProduct={currentProduct}
            />
          ))}
      </div>
    </VariantsStyled>
  );
};

const Variant = ({ variant, currentProduct }: any) => {
  const { handleProductModal } = useProduct({ product: variant });
  const isActive = (variantId: string) => variantId === currentProduct.id;

  return (
    <div
      className={`variant ${isActive(variant.id) && 'active-variant'}`}
      onClick={handleProductModal}
    >
      {variant && variant.Gallery && (
        <picture>
          <img
            src={`${imgSrcByEnv()}${variant.Gallery[0].image.fullpath}`}
            alt="product variant"
          />
        </picture>
      )}
      <div>{variant?.key}</div>
    </div>
  );
};

const VariantsStyled = styled.div`
  & > div {
    display: flex;
    gap: 10px;
    overflow-x: scroll;
  }
  p {
    font-size: 1.3rem;
    padding: 10px 0;
  }
  .variant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    min-width: 28%;
    max-width: 30%;
    object-fit: cover;
    border: 1px solid gray;
    padding: 5px;
    font-size: 1.3rem;
    img {
      height: 100px;
      width: 100%;
      object-fit: contain;
    }
    & > div:last-of-type {
      margin-top: 10px;
      height: 40px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
  .active-variant {
    border: 2px solid black;
    font-weight: 600;
  }
`;
