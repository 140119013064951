import React from "react";

import styled from "@emotion/styled";

import { Button, ButtonVariants } from "../../components/Button";
import { Header, HeaderVariants } from "../../components/Header";

export const MyItemsRoute = () => {
  return (
    <ItemsRouteStyled>
      <Header variant={HeaderVariants.myItems} />
      <div className="content">
        <img src="/files/walmart/walmart-myitems.svg" alt="my-items" />
        <p>Sign in to see and quickly reorder your essentials</p>
        <Button
          variant={ButtonVariants.addTransparent}
          content={{ text: "Sign up" }}
          onClick={(e: any) => e.preventDefault()}
        />
      </div>
    </ItemsRouteStyled>
  );
};

const ItemsRouteStyled = styled.div`
  min-height: calc(100vh - 55px);
  text-align: center;
  img {
    margin-top: 30%;
    width: 58%;
  }
  p {
    margin: 5% 10px 0;

    font-size: 1.55rem;
  }
  button {
    margin-top: 7%;
    display: inline-block;
    padding: 10px 25px;
  }
`;
