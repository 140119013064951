import React from "react";

import styled from "@emotion/styled";
import { Header, HeaderVariants } from "../../components/Header";
import { PopularServices } from "../../components/PopularServices";
import { Feedback } from "../../components/Feeback";
import { ProductsSlider } from "../../components/Modals/variants/ProductOverviewModal/components/SimilarProducts";
import { useQuery } from "@apollo/client";

import { GET_PRODUCTS } from "../../queries";
import { StoreTools } from "../../components/StoreTools";
import { MemberBenefits } from "../../components/MemberBenefits";

export const ServicesRoute = () => {
  const { data, loading } = useQuery(GET_PRODUCTS, {
    variables: {
      first: 10,
    },
  });

  const products =
    !loading && data.getEPogListing.edges.map((product: any) => product.node);

  return (
    <ServicesRouteStyled>
      <Header variant={HeaderVariants.search} />
      <div className="content">
        <StoreTools />
        <div className="member-benefits">
          <MemberBenefits />
        </div>
        <div className="product-slider">
          <ProductsSlider title="Recommended for you" products={products} />
        </div>
        <div className="popular-services">
          <PopularServices />
        </div>
        <Feedback />
      </div>
    </ServicesRouteStyled>
  );
};

const ServicesRouteStyled = styled.div`
  .content {
    padding: 0 15px;
  }

  .member-benefits {
    margin: 15px 0;
  }

  .product-slider {
    & > div {
      padding: 0;
    }
  }
  .popular-services {
    & > div {
      margin: 15px 0;
    }
  }
`;
