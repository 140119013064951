import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Instagram } from 'react-content-loader';
import { Link, useParams } from 'react-router-dom';
import styled from '@emotion/styled';

export const PickHarvest = () => {
  const {storeId} = useParams();
  const { data, loading } = useQuery(ListHarvestsQuery, {
    variables: {
      fullpath: `/ePog Product Data/Products/${ (storeId === 'Walmart') ? storeId : null }`,
    },
  });

  if (!loading) {
    const {
      getObjectFolder: { children },
    } = data;

    const ids = children.map(({ key }: any) => key);
    let uniqueIds = [...new Set(ids)];

    return (
      <PickHarvestStyled>
        <h2>Pick available harvest:</h2>
        {uniqueIds.map((harvest: any, key: number) => (
          <Link to={harvest} key={key}>
            {harvest}
          </Link>
        ))}
      </PickHarvestStyled>
    );
  } else {
    return <Instagram />;
  }
};

const PickHarvestStyled = styled.div`
  padding: 20px;
  h2 {
    font-weight: 600;
    font-size: 2rem;
  }
  a {
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    padding: 10px;
    display: block;
    margin: 10px 0;
  }
`;

const ListHarvestsQuery = gql`
  query GetObjectFolder($fullpath: String) {
    getObjectFolder(fullpath: $fullpath) {
      children {
        ... on object_Harvest {
          HarvestID
          key
        }
      }
    }
  }
`;
