import React from "react";

import { AccountRoute } from "./AccountRoute";
import { MainRoute } from "./MainRoute";
import { MyItemsRoute } from "./MyItemsRoute";
import { SearchMenuRoute } from "./SearchMenuRoute";
import { ServicesRoute } from "./ServicesRoute";

export const navLinks = [
  {
    text: "Shop",
    url: "/",
    iconActive: "/files/walmart/menu-icons/shop-active.png",
    iconDisabled: "/files/walmart/menu-icons/shop-disabled.png",
    component: <MainRoute />,
  },
  {
    text: "My Items",
    url: "/items",
    iconActive: "/files/walmart/menu-icons/my-items-active.png",
    iconDisabled: "/files/walmart/menu-icons/my-items-disabled.png",
    component: <MyItemsRoute />,
  },
  {
    text: "Search",
    url: "/search-menu/",
    iconActive: "/files/walmart/menu-icons/search-active.png",
    iconDisabled: "/files/walmart/menu-icons/search-disabled.png",
    component: <SearchMenuRoute />,
  },
  {
    text: "Services",
    url: "/services",
    iconActive: "/files/walmart/menu-icons/services-active.png",
    iconDisabled: "/files/walmart/menu-icons/services-disabled.png",
    component: <ServicesRoute />,
  },
  {
    text: "Account",
    url: "/account",
    iconActive: "/files/walmart/menu-icons/account-active.png",
    iconDisabled: "/files/walmart/menu-icons/account-disabled.png",
    component: <AccountRoute />,
  },
];
