import styled from "@emotion/styled";
import React from "react";
import { Button, ButtonVariants } from "../../../Button";

import { HeaderStyled } from "../../styles";

export const AccountHeader = () => {
  return (
    <HeaderStyled style={{ height: "100%" }}>
      <AccountHeaderStyled>
        <p>Account</p>
        <p>Save more. Live better.</p>
        <Button
          variant={ButtonVariants.addTransparent}
          content={{ text: "Sign in" }}
          onClick={() => {}}
        />
      </AccountHeaderStyled>
    </HeaderStyled>
  );
};

const AccountHeaderStyled = styled.div`
  padding: 6px 20px;
  height: 25vh;
  color: #fff;
  text-align: center;
  p:first-of-type {
    letter-spacing: 0.5px;
    font-size: 2.6rem;
    margin-top: 19%;
  }
  p:last-of-type {
    margin-top: 3%;
    font-size: 1.15rem;
  }
  button {
    margin-top: 7%;
    font-size: 1.3rem;
    font-weight: 500;
    padding: 10px 30px;
    display: inline-block;
    border: 1px solid #000;
  }
`;
