import React from "react";

import { useAppContext } from "../context";
import { actions } from "../context/actions";
import { IProduct } from "../types";
import { cutOffMessage, sendMessageWithUrl, normalizeNameForUrl, setWindowVars } from "../helpers";

export const useCartHook = () => {
  const {
    dispatch,
    value: { cart },
  } = useAppContext();
  const [totalCartValue, setTotalCartValue] = React.useState(0);
  const [totalItemsInCart, setTotalItemsInCart] = React.useState(0);
  const [checkoutMessage, setCheckoutMessage] = React.useState("");

  const handleCart = (
    e: any,
    { type, content }: { type: string; content: IProduct }
  ) => {
    e.stopPropagation();
    if (type === "add") {
      dispatch({ type: actions.ADD_TO_CART, payload: content });
    }
    if (type === "remove") {
      dispatch({
        type: actions.REMOVE_FROM_CART,
        payload: { product: content, type: "REMOVE_ONE" },
      });
    }
  };

  const checkout = (type: string, productList: [IProduct]) => {
    //dynataAddToCart(href + "cart/checkout");
    setWindowVars();
    if (productList.length <= 0) return; //when the cart is empty

    cutOffMessage();
    let href = (window.location.pathname).replace('cart', '');
    const products = productList.map((product: IProduct) => {
      let promoCode;
      product.URLPromoCode != null ? promoCode = '__promoCode-' + product.URLPromoCode : promoCode = '__promoCode-';
      let price = product.RegularPrice ? product.RegularPrice : product.Price;
      sendMessageWithUrl(href + 'ips/' + product.id + '__' + product.Brand + '__' + normalizeNameForUrl(product.Name) + '_' + price + promoCode + '_cartadd' + product.amount);
      dispatch({
        type: actions.CHECKOUT,
        payload: { product: { id: product.id, name: product.Name, regularPrice: product.RegularPrice, price: product.Price, source: 'cart', href: href }, type: 'CHECKOUT' },
      });
    });
    setCheckoutMessage("checkoutMessage");
  };
  
  React.useEffect(() => {
    if (window.finishIt == true) {
      checkout('checkout', cart);
      setWindowVars();
    }
  }, [window.finishIt])

  React.useEffect(() => {
      const totalValue = cart
      .reduce((prev: any, current: any) => {
        const price = current.Price ? current.Price : current.RegularPrice;
        let totalAmount
        if (current.PromoAmount && current.amount >= current.PromoAmount) { 
          totalAmount = ((Math.floor(current.amount / current.PromoAmount) * current.PromoCount * price) + ((current.amount % current.PromoAmount) * price));
        } else {
          totalAmount = (current.amount * price);
        }
        return totalAmount + prev;
      }, 0)
      .toFixed(2);
    setTotalCartValue(totalValue);

    const totalNumberItems = cart.reduce(
      (prev: any, current: any) => prev + current.amount,
      0
    );
    setTotalItemsInCart(totalNumberItems);
  }, [cart]);

  return { handleCart, totalCartValue, totalItemsInCart, checkout, checkoutMessage };
};
