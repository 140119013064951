import styled from "@emotion/styled";

interface IStyleProps {
  isSearchBarFocused?: boolean;
  isSearchMenu?: boolean;
}

export const SearchHeaderStyled = styled.div<IStyleProps>`
  position: relative;
  .search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${({ isSearchMenu }) =>
      !isSearchMenu ? "16px 6% 10px 5%" : "16px 6% 10px 4%"};
    min-height: 67px;
  }
  .chevron-back {
    svg {
      color: #fff;
      font-size: 2.2rem;
    }
  }
  .search-history {
    position: absolute;
    top: 67px;
    display: ${({ isSearchBarFocused }) =>
      isSearchBarFocused ? "block" : "none"};
    background: #fff;
    height: ${({ isSearchBarFocused }) =>
      isSearchBarFocused ? "calc(100vh - 55px)" : "auto"};
    width: 100%;
    z-index: 10;
    overflow-y: scroll;
    padding-bottom: 5%;
    .search-history-term {
      padding: 10px 0;
      margin: 0 10px;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        display: flex;
        align-items: center;
        svg {
          font-size: 1.4rem;
          margin-right: 10px;
        }
        span {
          font-size: 1.2rem;
        }
      }
      & > svg {
        transform: rotate(-45deg);
      }
    }
  }
  .cancel-focus {
    color: #fff;
    margin-left: 8px;
    font-size: 1.25rem;
  }
`;
