import React from "react";

import styled from "@emotion/styled";

import { Button, ButtonVariants } from "../Button";
import { useLocalization } from "../../hooks/useLocalization";

export const Feedback = () => {
  const {translation}=useLocalization();
  return (
    <FeedbackStyled>
      <p>{translation?.getLocalization?.WedLoveToHear || "We'd love to hear what you think!"}</p>
      <Button
        variant={ButtonVariants.addTransparent}
        onClick={() => {}}
        content={{ text: (translation?.getLocalization?.GiveFeedback || "Give Feedback") }}
      />
    </FeedbackStyled>
  );
};

const FeedbackStyled = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  border-bottom-width: 2px;
  text-align: center;
  padding: 30px 0;
  p {
    font-size: 1.5rem;
    font-weight: 300;
  }
  button {
    margin-top: 15px;
    text-align: center;
    display: inline-block;
    border-color: #000;
  }
`;
