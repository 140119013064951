import React from 'react';

import { ApolloProvider } from '@apollo/client';

import { Global } from '@emotion/react';
import { GlobalStylesWalmart } from './global-styles/GlobalStyle';
import { graphqlClient } from './config/graphql-client';
import WALMART_MOBILE from './walmart-mobile';

const Application = () => {
  return (
    <ApolloProvider client={graphqlClient}>
      <App />
    </ApolloProvider>
  );
};

const App = () => {
  return (
    <>
      <Global styles={GlobalStylesWalmart} />
      <WALMART_MOBILE />
    </>
  );
};

export default Application;
