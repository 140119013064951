import React from "react";

import { useAppContext } from "../../context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { AnimatePresence } from "framer-motion";

import Draggable from "react-draggable";
import { useModal } from "./useModal";
import { ModalContentStyled, ModalBGStyled } from "./styles";

export const ModalComponent = () => {
  const {
    value: { isModal },
  } = useAppContext();

  const {
    modalSelector,
    handleCloseModal,
    handleDragStart,
    handleDragStop,
    isDraggable,
  } = useModal();

  const nodeRef = React.useRef(null);

  const transitionVariants = {
    visible: {
      y: 0,
    },
    hidden: { y: `100vh` },
    exit: { y: `100vh` },
  };

  return (
    <>
      <AnimatePresence>
        {isModal && (
          <ModalBGStyled
            animate={{ opacity: 0.7 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
          ></ModalBGStyled>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isModal && (
          <ModalContentStyled
            animate={"visible"}
            initial={"hidden"}
            exit={"exit"}
            variants={transitionVariants}
            transition={{ ease: "easeIn" }}
          >
            <Draggable
              axis="y"
              nodeRef={nodeRef}
              onStart={handleDragStart}
              onStop={handleDragStop}
              bounds={{ top: 0 }}
              position={!isDraggable ? { x: 0, y: 0 } : undefined}
              cancel=".close-modal"
            >
              <div className="modal-content">
                <div className="modal-header" ref={nodeRef}>
                  <div className="drag-bar"></div>
                  <FontAwesomeIcon
                    onClick={handleCloseModal}
                    icon={faTimes}
                    className="close-modal"
                  />
                </div>
                <div className="content">{modalSelector()}</div>
              </div>
            </Draggable>
          </ModalContentStyled>
        )}
      </AnimatePresence>
    </>
  );
};
