import React from "react";
import { useParams } from "react-router-dom";
import { useConfiguration } from "./useConfigurationHook";
import { useQuery } from "@apollo/client";
import { GET_LOCALIZED } from "../queries";

export const useLocalization = () => {
  const { storeId = '', harvestId = '' } = useParams();
  const { LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });
  const { data } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });
  let translation = { getLocalization: { ...data?.getLocalizationAmzWm, ...data?.getLocalization } };
  
  return { translation };
};