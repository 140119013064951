import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode, faSearch } from "@fortawesome/free-solid-svg-icons";
import { fontColor, SearchInputStyled } from "./styles";
import { useCategoryNameHook } from "../../hooks/useCategoryNameHook";
import { useLocation } from "react-router-dom";
import { useFocus } from "../../hooks/useFocus";
import { useLocalization } from "../../hooks/useLocalization";

interface IProps {
  onFocus?: () => void;
  onSearchInputSubmit?: (e: any) => any;
  onSearchInputChange?: (e: any) => any;
  isSearchMenu?: boolean;
}

export const SearchInput = ({
  onFocus,
  onSearchInputSubmit,
  onSearchInputChange,
  isSearchMenu,
}: IProps) => {
  const { name } = useCategoryNameHook();
  const { state }: any = useLocation();
  const { translation } = useLocalization();

  const { setFocus, focusRef } = useFocus();

  React.useEffect(() => {
    state && state.isFocusInput && setFocus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchInputStyled isSearchMenu={isSearchMenu}>
      <FontAwesomeIcon icon={faSearch} color={fontColor} />
      <form onSubmit={onSearchInputSubmit} autoComplete="off">
        <input
          placeholder={(translation?.getLocalization?.Search || "Search")+" Walmart"}
          onFocus={onFocus}
          name="searchBar"
          defaultValue={name}
          onChange={onSearchInputChange}
          ref={focusRef}
          disabled={true}
        />
      </form>
      {/* <FontAwesomeIcon icon={faBarcode} color={fontColor} /> */}
    </SearchInputStyled>
  );
};
