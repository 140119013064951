import React from "react";

import styled from "@emotion/styled";

import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { FilterModalCommonStyles } from "../../commonStyles";
import { FilterModalButtons } from "../../../FilterModalButtons";
import { RadioComponent } from "../../../Radio";
import { useLocalization } from "../../../../hooks/useLocalization";

export const PickupAndShippingFilter = () => {
  const {translation}=useLocalization();
  return (
    <FilterModalCommonStyles>
      <PickupAndShippingStyled>
        <div>
          <h2>{translation?.getLocalization?.HowDoYouWantYourOrder || "How do you want your order?"}</h2>
          <FormControl component="fieldset">
            <RadioGroup name="shippingAndPickup">
              <FormControlLabel
                value="shipping"
                control={<RadioComponent />}
                label="Shipping"
              />
              <p>{translation?.getLocalization?.GetYourOrderShippedFast || "Get your order shipped fast via FedEx or UPS"}</p>
              <FormControlLabel
                value="pickup"
                control={<RadioComponent />}
                label="Pickup"
              />
              <p>{translation?.getLocalization?.WellBringItToYou || "We'll bring it to you curbside or come inside to pick it up"}</p>
            </RadioGroup>
          </FormControl>
          <div className="line-break"></div>
        </div>
        <FilterModalButtons />
      </PickupAndShippingStyled>
    </FilterModalCommonStyles>
  );
};

const PickupAndShippingStyled = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div:first-of-type {
    flex: 1;
  }

  h2 {
    font-size: 1.7rem;
    font-weight: 600;
  }

  .MuiFormControl-root {
    margin-bottom: 30px;
    p {
      margin-left: 22px;
      font-size: 1.1rem;
    }
  }

  .Mui-checked {
    color: #000 !important;
  }
  .MuiFormControlLabel-label {
    background-color: #e0effb;
    color: #3c7fb1;
    font-size: 1.2rem;
  }
  .line-break {
    height: 1px;
    background: #f2f2f2;
  }
`;
