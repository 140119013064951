import React from 'react';

import styled from '@emotion/styled';

import { Header, HeaderVariants } from '../../components/Header';
import { Categories } from '../../components/Categories';
import { useCategoriesHook } from '../../hooks/useCategoriesHook';
import { useParams } from 'react-router-dom';

export const SearchMenuRoute = () => {
  const { harvestId } = useParams();
  const { categories = [] }: any = useCategoriesHook({ harvestId }) || [];

  return (
    <SectionStyled>
      <Header variant={HeaderVariants.search} />
      <h2>Browse departments</h2>
      <Categories categories={categories} />
    </SectionStyled>
  );
};

const SectionStyled = styled.div`
  min-height: calc(100vh - 55px);
  h2 {
    font-weight: bolder;
    font-size: 1.8rem;
    letter-spacing: 1px;
    padding: 7% 4% 1%;
  }
`;
