import { motion } from "framer-motion";
import styled from "@emotion/styled";

export const ModalBGStyled = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`;

export const ModalContentStyled = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
  .modal-content {
    height: 100%;
    overflow: hidden;
  }
  .content {
    height: calc(100% - 120px);
    overflow: scroll;
    background: #fff;
  }
  .drag-bar {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
    width: 40px;
    background-color: gray;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .modal-header {
    * {
      -webkit-tap-highlight-color: transparent;
    }
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #fff;
    margin-top: 67px;
    z-index: 5;
    position: sticky;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 55px;
    width: 100%;
    padding: 0 15px;
    border: hidden;
    .close-modal {
      position: relative;
      z-index: 10;
      font-size: 2.3rem;
      margin-top: 7px;
    }
  }
`;
