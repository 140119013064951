import styled from "@emotion/styled";

export const sectionPadding = "17px";
export const sectionRadius = "10px";
export const CartStyled = styled.div`
  height: 100%;
  .cart-container {
    border-radius: ${sectionRadius};
    border: 1px solid #f2f2f2;
    border-bottom-width: 2px;
    margin: ${sectionPadding};
  }
  .line-break {
    height: 1px;
    background: #f2f2f2;
  }

  .cart-footer {
    background: #fff;
    border-top: 1px solid #f2f2f2;
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: ${sectionPadding};
    & > div {
      text-align: end;
      text-align: end;
      font-size: 1.25rem;
      font-weight: 700;
      letter-spacing: 0.5px;
      margin-bottom: 10px;
    }
    & > button {
      width: 100%;
      padding: 12px 20px;
      justify-content: center;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
  .subtotal,
  .estimated-total {
    padding: ${sectionPadding};
    display: flex;
    justify-content: space-between;
    & > span:first-of-type {
      font-weight: bolder;
      font-size: 1.3rem;
    }
  }
  .estimated-total {
    & > span:last-of-type {
      font-size: 1.3rem;
      font-weight: bolder;
    }
  }
`;
