import React from "react";

import { AnimatePresence, motion } from "framer-motion";

import { useAppContext } from "../../../../context";
import { IProduct } from "../../../../types";

import { ButtonStyles } from "../../commonStyles/buttonStyles";
import { sendMessageWithUrl, normalizeNameForUrl } from "../../../../helpers";

interface Icontent {
  text: string;
  product: IProduct;
}

interface Iprops {
  onClick: any;
  content: Icontent;
  styles?: string;
  url?: any;
}

export const AddToCart = ({ onClick, content, styles, url }: Iprops) => {
  const { value } = useAppContext();

  const productInCart = value.cart.find(
    (product: IProduct) => product.id === content?.product?.id
  );

  let amount = 0;

  if (productInCart) amount = productInCart.amount;

  const logMessage = (argAmount: number) => {
    if (!content.product) return

    const { categoryId, storeId, harvestId } = url ? url : { categoryId: '', storeId: '', harvestId: '' };
    const product = content.product;
    const price = product?.Price != 0 ? product?.Price : product?.RegularPrice;
    const brand = product?.Brand ? product?.Brand : '';
    let promoCode = (product.URLPromoCode != null) ? ('__promoCode-' + product.URLPromoCode) : '__promoCode-';

    let productUrl = (storeId ? `/${storeId}/${harvestId}` : `/${harvestId}`);
    productUrl += (categoryId ? `/ip/${categoryId}` : `/ips`);
    productUrl += (`/${product?.id}__${brand}__${normalizeNameForUrl(product?.Name)}_${price}_${promoCode}`);

    sendMessageWithUrl(productUrl + '_cartadd' + argAmount);
  }

  const isAmount = amount === 0 ? false : true;

  const transition = { ease: "linear", duration: 0.2 };

  const variants = {
    animate: {
      padding: "0 8px",
      transition,
    },
    initial: { padding: 0, transition },
    exit: { padding: 0, transition },
  };

  return (
    <ButtonStyles
      onClick={(e: any) => e.stopPropagation()}
      styles={styles}
      isPressed={isAmount}
    >
      {!isAmount && (
        <motion.span
          onClick={(e) => {
            logMessage(amount + 1);
            onClick(e, { type: "add", content: content.product });
          }}
          className="button-content"
        >
          {content.text}
        </motion.span>
      )}
      <div>
        <AnimatePresence exitBeforeEnter>
          {isAmount && (
            <motion.span
              variants={variants}
              animate="animate"
              initial="initial"
              exit="exit"
            ></motion.span>
          )}
        </AnimatePresence>

        {isAmount && (
          <>
            <span
              onClick={(e) => {
                logMessage(amount - 1);
                onClick(e, { type: "remove", content: content.product });
              }
              }
            >
              &ndash;
            </span>
            <span className="button-content">{amount}</span>
            <span
              onClick={(e) => {
                logMessage(amount + 1);
                onClick(e, { type: "add", content: content.product });
              }
              }
            >
              +
            </span>
          </>
        )}
        <AnimatePresence exitBeforeEnter>
          {isAmount && (
            <motion.span
              variants={variants}
              animate="animate"
              initial="initial"
              exit="exit"
            ></motion.span>
          )}
        </AnimatePresence>
      </div>
    </ButtonStyles>
  );
};
