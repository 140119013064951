import styled from "@emotion/styled";

export const FiltersStyled = styled.div`
  padding: 16px 0;
  position: relative;

  & > div {
    padding-top:20px;
    overflow-x: scroll;
    white-space: nowrap;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 15px;

    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }
`;
