export const createIcon = ({
  rating,
  icons,
  max,
}: {
  rating: number;
  icons: any;
  max: number;
}) => {
  let i = +rating;

  const iconsToPrint = [];
  const { complete, half, empty } = icons;

  for (i; i >= 1; i -= 1) {
    iconsToPrint.push({ name: complete, type: "complete" });
  }

  if (i >= 0.5 && i <= 1) {
    iconsToPrint.push({ name: half, type: "half" });
  }

  while (iconsToPrint.length < max) {
    iconsToPrint.push({ name: empty, type: "empty" });
  }

  return iconsToPrint;
};

export const sortByRating = (reviews: any) => {
  let reviewsByRating = {
    5: 0,
    4: 0,
    3: 0,
    2: 0,
    1: 0,
  };

  reviews.forEach((review: any) => {
    const reviewNumber = parseFloat(review.rating);
    if (reviewNumber >= 1 && reviewNumber < 2) {
      reviewsByRating[1] = reviewsByRating[1] + 1;
    }
    if (reviewNumber >= 2 && reviewNumber < 3) {
      reviewsByRating[2] = reviewsByRating[2] + 1;
    }
    if (reviewNumber >= 3 && reviewNumber < 4) {
      reviewsByRating[3] = reviewsByRating[3] + 1;
    }
    if (reviewNumber >= 4 && reviewNumber < 5) {
      reviewsByRating[4] = reviewsByRating[4] + 1;
    }
    if (reviewNumber === 5) {
      reviewsByRating[5] = reviewsByRating[5] + 1;
    }
  });
  return reviewsByRating;
};

export const normalizeNameForUrl = (name: string|null) => {
  if (!name) return '';
  return name
      .normalize('NFD')
      .replaceAll(' ', '_')
      .replace(/[^a-zA-Z0-9_ ]/g, '');
}

export const sendMessageWithUrl = (url: any) => {
  //http://localhost:3000/Walmart/Diapers%20A_old/ips/5018__Huggies__Huggies_Little_Snugglers_Comfortable_and_LatexFree_Diapers_Size_1_168_Count_65.8_cartadd
  //console.log('{qid: "qURLs", value: "https://generic.e-pog.com' + url + '"}')
  sendMessage('{qid: "qURLs", value: "https://wm-mobile.e-pog.com' + url + '"}');
}

export const cutOffMessage = () => {
  //http://localhost:3000/Walmart/Diapers%20A_old/ips/5018__Huggies__Huggies_Little_Snugglers_Comfortable_and_LatexFree_Diapers_Size_1_168_Count_65.8_cartadd
  //console.log('{qid: "qURLs", value: "https://generic.e-pog.com' + url + '"}')
  sendMessage('{qid: "qURLs", value: "CHECKOUT"}');
}

//sendMessage({qid: "qURLs",value: "https://generic.e-pog.com/Sams%20Club/Toilet%20Paper%20C/ips/15227__POM__POM_Bath_Tissue_Septic_Safe_2Ply_White_473_sheetsroll_45_rolls_27.98_4"})
export const sendMessage = (msg: string) => {
  window.parent.postMessage(msg.replaceAll('%20', ' '), '*');
  console.log('message sent: ' + msg.replaceAll('%20', ' '));
}

export const setWindowVars = () => {
  window.finishIt = false;
  window.triggerPath = '';
}
