import React from "react";

import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export const MemberBenefits = () => {
  return (
    <MemberBenefitsStyled>
      <div className="title">
        <img src="/files/walmart/walmart-plus.png" alt="walmart plus" />
        <span>Member benefits</span>
      </div>
      <Benefit
        img="/files/walmart/fuel.svg"
        title="Member prices on fuel"
        text="Save 5¢ per gallon at participating fuel stations."
      />
      <Benefit
        img="/files/walmart/save.svg"
        title="Save $816 per year"
        text="With your free delivery from store."
      />
      <Benefit
        img="/files/walmart/benefits.svg"
        title="Plus more benefits you'll adore"
        text="Chek out as you shop to make store shopping fast."
        borderBottom={false}
      />
    </MemberBenefitsStyled>
  );
};

const MemberBenefitsStyled = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 15px;
  background: url("/files/walmart/free-sheeping-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  .title {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 0 8px;

    img {
      max-height: 30px;
      width: auto;
    }
    span {
      margin-left: 4px;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
`;

const Benefit = ({
  img,
  title,
  text,
  borderBottom,
}: {
  img: string;
  title: string;
  text: string;
  borderBottom?: boolean;
}) => {
  return (
    <BenefitStyled borderBottom={borderBottom}>
      <img src={img} alt={text} />
      <div>
        <div>
          <p>{title}</p>
          <p>{text}</p>
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </BenefitStyled>
  );
};

const BenefitStyled = styled.div<{ borderBottom?: boolean }>`
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: ${({ borderBottom }) =>
    borderBottom !== false && "1px solid #e5e5e5"};

  & > img {
    height: 52px;
    width: auto;
  }
  & > div {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-left: 5%;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        font-size: 1.3rem;
        line-height: 1.5;
      }
      p:first-of-type {
        font-weight: 600;
      }
      p:last-of-type {
        font-weight: 300;
      }
    }
    svg {
      font-size: 16px;
    }
  }
`;
