import React from "react";

import styled from "@emotion/styled";

export const Label = ({
  productDetails,
  children,
}: {
  children: string;
  productDetails?: boolean;
}) => {
  return <LabelStyled productDetails={productDetails}>{children}</LabelStyled>;
};

const LabelStyled = styled.div<{ productDetails?: boolean }>`
  width: fit-content;
  padding: 6px 10px;
  border-radius: 2px;
  background-color: ${({ productDetails }) => !productDetails && "#e0effb"};
  border: ${({ productDetails }) => productDetails && "1px solid #3c7fb1"};
  color: #3c7fb1;
  font-size: 1.1rem;
`;
