import { css } from '@emotion/react';

export const GlobalStylesWalmart = css`
  html,
  body,
  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  ol,
  ul,
  li {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    line-height: 1;
    padding-bottom: 7%;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }
`;
export const GlobalStylesGeneric = css`
  html,
  body,
  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  ol,
  ul,
  li {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    line-height: 1;
  }
`;
