import React from "react";

import { Button, ButtonVariants } from "../Button";

import styled from "@emotion/styled";

export const FreeTrial = () => {
  return (
    <ComponentStyled>
      <img src="/files/walmart/walmartPlusLogo.svg" alt="logo" />
      <div>
        <h2>
          Members get free shipping* with <br /> no order minimun!
        </h2>
        <p>Restrictions apply.</p>
        <Button
          variant={ButtonVariants.addTransparent}
          content={{ text: "Try free 30-day trial" }}
          onClick={() => {}}
        />
      </div>
    </ComponentStyled>
  );
};

const ComponentStyled = styled.div`
  background: url("/files/walmart/free-sheeping-bg.svg");
  background-size: cover;
  background-position-y: 90%;
  position: relative;
  padding: 20px 0 20px 20%;
  img {
    top: 20px;
    left: 20px;
    position: absolute;
    width: 55px;
  }
  & > div {
    width: 85%;
    margin: 0 auto;
    h2 {
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.5;
    }
    p {
      font-weight: 300;
      font-size: 1.4rem;
      margin-top: 10px;
      margin-left: 3px;
    }
    button {
      margin-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;
