import { useQuery } from "@apollo/client";
import { GET_CONFIGURATION } from '../queries';
import { Iconfig } from '../types/config';

export const useConfiguration = ({
  storeId,
  harvestId,
}: {
  storeId: string;
  harvestId: string;
}): Iconfig => {
  let store = (storeId ? storeId : "Walmart");
  const path = `/ePog Product Data/Products/${store}/${harvestId}`
  const { data, loading, error } = useQuery(GET_CONFIGURATION, {
    variables: {
      // fullpath: `/ePog Product Data/Products/${storeId}/${harvestId}`,
      fullpath: path,
    },
    skip: !harvestId,
  });

  if (!loading && harvestId && !error) {
    const template = data.getHarvest.Template;

    return {
      loading,
      ...template,
    };
  } else {
    return { loading, error };
  }
};
