import React from "react";

import styled from "@emotion/styled";

import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonVariants } from "../Button";

export const ErrorPage = ({
  errorText,
  navigateTo,
}: {
  errorText?: string;
  navigateTo?: string;
}) => {
  const navigate = useNavigate();
  const { storeId, harvestId } = useParams();

  return (
    <ErrorPageStyled>
      <img src="/files/walmart/no-search-results.svg" alt="no search results" />
      <p>{errorText ? errorText : "There were no search results"} </p>
      <div className="component-footer">
        <p>Please check spelling or use different keywords.</p>
        <Button
          variant={ButtonVariants.blueGeneric}
          onClick={() => navigate(navigateTo ? navigateTo : `/${storeId}/${harvestId}`)}
          content="Return to home"
        />
      </div>
    </ErrorPageStyled>
  );
};

const ErrorPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15% 15px;
  height: 80vh;

  img {
    height: auto;
    width: 40%;
  }
  & > p {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }
  .component-footer {
    text-align: center;
    p {
      font-size: 1.3rem;
    }
    button {
      margin: 10% auto 0;
    }
  }
`;
