import React from 'react';

import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { FooterNav } from '../components/FooterNav';
import { ModalComponent } from '../components/Modals';
import { ErrorPage } from '../components/ErrorPage';
import { PickHarvest } from '../components/PickHarvest';
import { TransitionDimmer } from '../components/TransitionDimmer';
import { CartRoute } from './CartRoute';
import { MainRoute } from './MainRoute';
import { navLinks } from './navLinks';
import { SearchTermRoute } from './SearchTermRoute';
import { sendMessageWithUrl } from '../helpers';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path=":storeId/" element={<PickHarvest />} />

      <Route
        path=":storeId/:harvestId/*"
        element={
          <Layout>
            <ErrorPage errorText="Error while loading page!" />
          </Layout>
        }
      />

      <Route
        path=":storeId/:harvestId/"
        element={
          <Layout>
            <MainRoute />
          </Layout>
        }
      ></Route>

      {navLinks
        .filter((item) => item.text !== 'Shop')
        .map((link, index) => (
          <Route
            key={index}
            path={`:storeId/:harvestId${link.url}`}
            element={<Layout>{link.component}</Layout>}
          />
        ))}

      <Route path=":storeId/:harvestId/search/:termId">
        <Route
          path=":productId"
          element={
            <Layout>
              <SearchTermRoute />
            </Layout>
          }
        />
        <Route
          path=""
          element={
            <Layout>
              <SearchTermRoute />
            </Layout>
          }
        />
      </Route>

      <Route
        path=":storeId/:harvestId/cart"
        element={
          <Layout>
            <CartRoute />
          </Layout>
        }
      />
    </Routes>
  );
};

const Layout = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const {storeId,harvestId} = useParams();
  
  React.useEffect(() => {
    // if it's in search page, dont send message (its sent via product component)
    if (window.location.pathname.includes('/search/') && (window.location.pathname.split('/')[5]))
      return;
    sendMessageWithUrl(window.location.pathname + window.location.hash.replace('#', '?__'));
  }, [window.location.pathname]);
  
  React.useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.message == "buttonClick") {
        window.finishIt = true;
        window.triggerPath = window.location.pathname;
        const url = (storeId ? `/${storeId}/${harvestId}` : `/${harvestId}`);
        navigate(`${url}/cart`);
      }
    });
  }, []);

  return (
    <>
      {children}
      <FooterNav />
      <ModalComponent />
      <TransitionDimmer />
    </>
  );
};
