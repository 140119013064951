import React from 'react';

import styled from '@emotion/styled';
import { FilterModalCommonStyles } from '../../commonStyles';
import { FilterModalButtons } from '../../../FilterModalButtons';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useAppContext } from '../../../../context';
import { actions } from '../../../../context/actions';
import { AnimatePresence, motion } from 'framer-motion';
import { RadioComponent } from '../../../Radio';
import { useLocalization } from '../../../../hooks/useLocalization';

export const SortAndFilterModal = () => {
  const { dispatch } = useAppContext();
  const { translation } = useLocalization();
  const handleSubmit = () => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalContent: null },
    });
  };
  const handleClearSorting = () => {
    dispatch({
      type: actions.REMOVE_PRODUCTS_SORTING,
    });
  };

  return (
    <FilterModalCommonStyles>
      <ComponentStyled>
        <h2>{translation?.getLocalization?.SortAndFilter || 'Sort and filter'}</h2>
        <SortComponent handleClearSorting={handleClearSorting} translation={translation} />
        <FilterModalButtons
          onSubmit={handleSubmit}
          onClear={handleClearSorting}
        />
      </ComponentStyled>
    </FilterModalCommonStyles>
  );
};

const ComponentStyled = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 1.7rem;
    font-weight: 600;
  }
`;

const SortComponent = ({ handleClearSorting, translation }: any) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const {
    value: { productsSorting },
    dispatch,
  } = useAppContext();

  const sortValue = productsSorting.find(
    (filter: any) => filter.name === 'Price'
  )?.value;

  const [value, setValue] = React.useState(sortValue);

  React.useEffect(() => {
    setValue(sortValue);
  }, [productsSorting, sortValue]);

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: actions.ADD_PRODUCTS_SORTING,
      payload: { name: 'Price', value: e.target.value },
    });
  };

  return (
    <SortComponentStyled>
      <div className="sort-header" onClick={() => setIsOpen(!isOpen)}>
        <span>{translation?.getLocalization?.Sort || 'Sort'}</span>
        <div>
          <span onClick={handleClearSorting}>{value && <u>{translation?.getLocalization?.Clear || 'clear'}</u>}</span>
          {isOpen ? (
            <span className="dash">—</span>
          ) : (
            <span className="plus">+</span>
          )}
        </div>
      </div>
      <div className="sort-list-container">
        <AnimatePresence>
          {isOpen && (
            <motion.div
              animate={{ y: 0, height: "auto", opacity: 1 }}
              exit={{ y: -100, height: 0, opacity: 0 }}
              initial={{ y: -100, height: 0, opacity: 0 }}
              transition={{ ease: 'linear', duration: 0.2 }}
              className="sort-list"
            >
              <FormControl>
                <RadioGroup onChange={handleSelect} value={value || ''}>
                <FormControlLabel
                    value="Alphabet:A>Z"
                    control={<RadioComponent />}
                    label={translation?.getLocalization?.AlphabetAZ || "Alphabet: A > Z"}
                  />
                  <FormControlLabel
                    value="Alphabet:Z>A"
                    control={<RadioComponent />}
                    label={translation?.getLocalization?.AlphabetAZ || "Alphabet: Z > A"}
                  />
                  <FormControlLabel
                    value="Price:low>high"
                    control={<RadioComponent />}
                    label={translation?.getLocalization?.PriceLH || "Price: low to high"}
                  />
                  <FormControlLabel
                    value="Price:high>low"
                    control={<RadioComponent />}
                    label={translation?.getLocalization?.PriceHL || "Price: high to low"}
                  />
                  <FormControlLabel
                    value="Rating:low>high"
                    control={<RadioComponent />}
                    label={translation?.getLocalization?.RatingLH || "Rating: low to high"}
                  />
                  <FormControlLabel
                    value="Rating:high>low"
                    control={<RadioComponent />}
                    label={translation?.getLocalization?.RatingHL || "Rating: high to low"}
                  />
                </RadioGroup>
              </FormControl>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </SortComponentStyled>
  );
};

const SortComponentStyled = styled.div`
  flex: 1;

  .sort-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    height: 30px;
    & > span {
      font-size: 1.7rem;
      font-weight: 500;
    }
    & > div {
      span:first-of-type {
        font-size: 1.5rem;
        font-weight: 400;
      }
      span:last-of-type {
        margin-left: 30px;
      }
    }
    .dash {
      font-size: 2rem;
    }
    .plus {
      font-size: 3rem;
    }
  }
  .sort-list-container {
    overflow: hidden;
  }
  .sort-list {
    margin-top: 5%;

    .MuiFormControlLabel-label {
      font-size: 1.3rem;
    }
  }
`;
