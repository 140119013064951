import React from 'react';

import { PriceRangeFilter } from './variants/PriceRangeFilter';
import { BrandsFilterModal } from './variants/BrandsFilter';
import { DeliveryDateFilter } from './variants/DeliveryDateFilter';
import { CategoriesFilterModal } from './variants/CategoriesFilter';
import { RatingFilter } from './variants/RatingFilter';
import { SortAndFilterModal } from './variants/SortAndFilterModal';
import { ProductOverviewModal } from './variants/ProductOverviewModal';
import { PickupAndShippingFilter } from './variants/PickupAndShippingFilter';
import { VariantsFilter } from './variants/VariantsFilter';

import { ModalTypes } from './types';
import { useAppContext } from '../../context';

import { actions } from '../../context/actions';
import { SpecialOffersFilterModal } from './variants/SpecialOffersFilter';

const body = document.querySelector('body');

export const useModal = () => {
  const {
    value: { isModal, modalType, modalContent },
    dispatch,
  } = useAppContext();

  const [isDraggable, setIsDraggable] = React.useState(false);

  React.useEffect(() => {
    if (body) {
      if (isModal) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'auto';
      }
    }
  }, [isModal]);

  const handleCloseModal = () =>
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalType: null, modalContent: null },
    });

  const handleDragStart = () => {
    setIsDraggable(true);
  };

  const handleDragStop = (_: any, data: any) => {
    data.y > 50 && handleCloseModal();
    setIsDraggable(false);
  };

  const modalSelector = () => {
    switch (modalType as ModalTypes) {
      case ModalTypes.sortAndFilter:
        return <SortAndFilterModal />;
      case ModalTypes.productOverview:
        return (
          <ProductOverviewModal
            product={modalContent.product}
            route={modalContent.route}
          />
        );
      case ModalTypes.pickupAndShipping:
        return <PickupAndShippingFilter />;
      case ModalTypes.priceRange:
        return <PriceRangeFilter />;
      case ModalTypes.brands:
        return <BrandsFilterModal termId={modalContent} />;
      case ModalTypes.deliveryDate:
        return <DeliveryDateFilter />;
      case ModalTypes.categories:
        return <CategoriesFilterModal productsBeforeFiltering={modalContent?.products} />;
      case ModalTypes.rating:
        return <RatingFilter />;
      case ModalTypes.variants:
        return <VariantsFilter />;
      case ModalTypes.specialOffers:
        return <SpecialOffersFilterModal productsBeforeFiltering={modalContent?.products} />;
      default:
        return false;
    }
  };

  return {
    modalSelector,
    handleCloseModal,
    handleDragStart,
    handleDragStop,
    isDraggable,
  };
};
