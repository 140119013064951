import React from 'react';

import styled from '@emotion/styled';

import { Carousel } from '../../components/Carousel';
import { FreeTrial } from '../../components/FreeTrial';
import { Header, HeaderVariants } from '../../components/Header';
import { ProductsSlider } from '../../components/Modals/variants/ProductOverviewModal/components/SimilarProducts';
import { ShopLocation } from '../../components/ShopLocation';
import { Feedback } from '../../components/Feeback';
import { FavoriteDepartments } from '../../components/FavoriteDepartments';
import { PopularServices } from '../../components/PopularServices';

import { useCategoriesHook } from '../../hooks/useCategoriesHook';
import { useMainRoute } from './useMainRoute';
import { useParams } from 'react-router-dom';
import { Instagram } from 'react-content-loader';

export const MainRoute = () => {
  const { harvestId } = useParams();
  const { categories, categoriesLoading }: any =
    useCategoriesHook({ harvestId, numberOfItems: 8 }) || [];

  const { carouselItems, products, translation } = useMainRoute() || [];

  if (categoriesLoading) {
    return <Instagram />;
  } else {
    return (
      <RouteStyled>
        <Header variant={HeaderVariants.home} />
        <ShopLocation
          shopName="Secaucus Supercenter"
          shopLocation="Bronx 10451"
        />
        <div className="content">
          {carouselItems && <Carousel items={carouselItems} />}
          <div className="free-trial">
            <FreeTrial />
          </div>
        </div>
        {products && (
          <div>
            <ProductsSlider
              title={translation?.getLocalization?.BuildYourCart || "Build your cart"}
              products={products.shuffledTrending}
            />
          </div>
        )}
        {categories && (
          <div>
            <FavoriteDepartments categories={categories} />
          </div>
        )}

        {products && (
          <div>
            <ProductsSlider title={translation?.getLocalization?.Trending || "Trending"} products={products.shuffledCart} />
          </div>
        )}

        <div className="popular-services">
          <PopularServices />
        </div>

        <Feedback />
      </RouteStyled>
    );
  }
};

const RouteStyled = styled.div`
  .content {
    padding: 15px;
  }
  .free-trial {
    margin-top: 32px;
  }
`;
