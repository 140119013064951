import styled from "@emotion/styled";

export const TermBannerStyled = styled.div`
  padding: 16px;
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  max-width:1250px;
  .banner {
    padding-right: 16px;
    img {
      max-height: 70px;
      object-fit: contain;
    }
    p {
      max-width: 142px;
      max-height: 42px;
      line-height: 1.5;
      margin-top: 10px;
      overflow: hidden;
    }
    p:first-of-type {
      font-size: 1.4rem;
      font-weight: 700;
    }
    p:last-of-type {
      font-size: 1.2rem;
      font-weight: 400;
      // color: #74767c;
      color: #000;
      text-align: center;
    }
  }
  .products {
    display: flex;
    overflow-y: scroll;
    gap: 16px;
  }
  .product {
    min-width: 100px;
    img {
      height: 65px;
      object-fit: contain;
    }
    p {
      font-size: 1.4rem;
      color: #6e6f70;
      margin-top: 10px;
      &:last-of-type {
        height: 42px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }
    button{
      margin-top:10px;
      max-width:90%;
    }
    .price{
      font-weight:700;
      color:#000;
      .oldPrice {
        margin-left: 4px;
        font-size: 12px;
        color: #999999;
        text-decoration: line-through
      }
    }
    .ratings {
      margin-top: 10px;
      display: flex;
      & > span {
        margin-left: 7px;
      }
    }
  }
`;

export const BannerVideo = styled.video<{isVideoOnTop:boolean}>`
display:block;
width:100%;
margin: 0 auto;
margin-top: ${({ isVideoOnTop }) => isVideoOnTop ? "0" : "10px"};
margin-bottom: ${({ isVideoOnTop }) => isVideoOnTop ? "10px" : "0"};
`