import React from 'react';

import styled from '@emotion/styled';

import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../context';
import { actions } from '../../context/actions';

export const Categories = ({ categories }: any) => {
  const { dispatch } = useAppContext();
  const { storeId, harvestId } = useParams();

  const navigate = useNavigate();
  const navigateTo = (title: string, id: string, key: string) => {
    dispatch({
      type: actions.ADD_PRODUCTS_INFROMATION,
      payload: { categoryTitle: title },
    });
    const url = (storeId ? `/${storeId}/${harvestId}` : `/${harvestId}`);
    navigate(`${url}/search/${id}__${key.replaceAll(' ', '_')}`);
  };

  return (
    <CategoriesStyled>
      {categories.map(
        (category: any, index: number) =>
          category.fullpath && (
            <div
              key={index}
              onClick={() =>
                navigateTo(category.fullpath, category.id, category.key)
              }
            >
              <span>{category.key}</span>
              {/* <img src={item.imgSrc} alt="category" /> */}
            </div>
          )
      )}
    </CategoriesStyled>
  );
};

const CategoriesStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
  padding: 0 3.3% 15px;
  & > div {
    margin-top: 4%;
    min-height: 145px;
    width: 30.5%;
    padding: 10px 0;
    border: 1px solid #f2f2f2;
    border-bottom-width: 2px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 8%;
      color: #154b93;
      font-size: 1.5rem;
      letter-spacing: 0.4px;
      line-height: 1.2;
      @media (max-width: 350px) {
        font-size: 1rem;
      }
    }
    img {
      opacity: 0.1;
      width: 100%;
      margin-left: -30%;
    }
  }
`;
