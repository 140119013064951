import React from "react";

import styled from "@emotion/styled";

import Slider from "@mui/material/Slider";
import { FilterModalCommonStyles } from "../../commonStyles";
import { FilterModalButtons } from "../../../FilterModalButtons";
import { useLocalization } from "../../../../hooks/useLocalization";

export const DeliveryDateFilter = () => {
  const [value, setValue] = React.useState(0);

  const {translation}=useLocalization();
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <FilterModalCommonStyles>
      <DeliveryDateStyled>
        <div>
          <h2>{translation?.getLocalization?.HowFastDoYouWantYourOrder || "How fast do you want your order?"}</h2>
          <p>{translation?.getLocalization?.Anytime || "Anytime"}</p>

          <div className="slider">
            <Slider
              value={value}
              onChange={handleChange}
              max={3}
              marks={[
                { value: 0, label: (translation?.getLocalization?.Today || `Today`) },
                { value: 1, label: (translation?.getLocalization?.Tommorow || `Tommorow`) },
                { value: 2, label: (translation?.getLocalization?.TwoDays || `2 days`) },
                { value: 3, label: (translation?.getLocalization?.Anytime || `Anytime`) },
              ]}
            />
          </div>
        </div>
        <FilterModalButtons />
      </DeliveryDateStyled>
    </FilterModalCommonStyles>
  );
};

const DeliveryDateStyled = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div:first-of-type {
    flex: 1;
  }

  h2 {
    font-size: 1.7rem;
    font-weight: 600;
  }

  .slider {
    width: 85%;
    margin: 0 auto;
    .MuiSlider-root {
      margin-bottom: 0;
    }
    .MuiSlider-rail,
    .MuiSlider-track {
      height: 1px;
    }
  }
  p:first-of-type {
    margin-top: 15%;
    text-align: center;
    font-weight: 600;
    font-size: 1.8rem;
  }
`;
