import React from 'react';

import styled from '@emotion/styled';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

import { Button, ButtonVariants } from '../Button';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';

export const Carousel = ({ items }: any) => {
  const [isPlaying, setIsPlaying] = React.useState(true);

  const swiperRef = React.useRef<SwiperCore>();
  const onInit = (Swiper: SwiperCore): void => {
    swiperRef.current = Swiper;
  };

  React.useEffect(() => {
    if (swiperRef.current) {
      if (isPlaying) {
        swiperRef.current.autoplay.start();
      } else {
        swiperRef.current.autoplay.stop();
      }
    }
  }, [isPlaying]);

  return (
    <CarouselStyled numberOfBullets={items ? items.length : 0}>
      <Swiper
        slidesPerView={1}
        spaceBetween={50}
        className="swiper"
        modules={[Pagination, Autoplay]}
        pagination={true}
        onInit={onInit}
      >
        {items &&
          items.map((slide: any, key: number) => (
            <SwiperSlide key={key}>
              <picture>
                <img
                  src={`${imgSrcByEnv()}${slide?.Image?.fullpath}`}
                  alt={slide.Title}
                />
              </picture>
              <div className="slide-content">
                <h2>{slide.Title}</h2>
                <p>{slide.Content}</p>
                <Button
                  variant={ButtonVariants.addTransparent}
                  content={{ text: slide.ButtonLabel }}
                  onClick={() => {}}
                />
              </div>
            </SwiperSlide>
          ))}
        <FontAwesomeIcon
          icon={isPlaying ? faPause : faPlay}
          className="controls"
          onClick={() => setIsPlaying(!isPlaying)}
        />
      </Swiper>
    </CarouselStyled>
  );
};

const borderRadius = '10px';

const CarouselStyled = styled.div<{
  numberOfBullets: number;
}>`
  .swiper {
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
  }

  img {
    height: 215px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .slide-content {
    padding: 17px;
    padding-bottom: 67px;
    border: 1px solid #cacaca;
    border-top: none;
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
    h2 {
      margin-top: 2px;
      font-size: 1.6rem;
      font-weight: 600;
    }
    p {
      margin-top: 10px;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
    }
    button {
      margin-top: 17px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .swiper-pagination-bullet {
    border-radius: 0;
    height: 1px;
    width: 50px;
    margin: 0 !important;
    padding: 0 !important;
  }
  .swiper-pagination {
    bottom: 17px;
  }

  .controls {
    position: absolute;
    bottom: 15px;
    left: 60px;
    z-index: 10;
  }
`;
