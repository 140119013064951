import React from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../context';
import { actions } from '../../context/actions';
import { GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { ModalTypes } from '../../components/Modals/types';
import {
  handleFiltering,
  handleSorting,
} from '../../components/Products/helpers';
import { IProduct } from '../../types';
import { useLocalization } from '../../hooks/useLocalization';

export const useSearchTermRoute = () => {
  const {
    dispatch,
    value: { searchFilters, productsSorting },
  } = useAppContext();
  const { translation } = useLocalization();

  const { termId, productId } = useParams();

  const { data, loading } = useQuery(GET_PRODUCT_CATEGORY_BY_TERM({ termId }));

  const brandFilter = searchFilters.find(
    (filter: any) => filter.name === 'Brand'
  );

  const handleBrandsFilter = (name: string, bool: boolean) => {
    const activatedBrands = brandFilter?.brands || {};
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: {
        name: 'Brand',
        brands: { ...activatedBrands, [name]: bool },
      },
    });
  };

  const activatedFilters =
    brandFilter &&
    Object.entries(brandFilter?.brands).map(([key, value]) => value && key);

  const handleProducts = (products: IProduct[]) => {
    const sortProducts = handleSorting({ products, productsSorting });
    const filterProducts = handleFiltering({
      products: sortProducts,
      searchFilters,
    });
    return filterProducts;
  };

  React.useEffect(() => {
    const isData =
      data && data.getEPogTermListing && data.getEPogTermListing.edges[0];
    if (isData && (data.getEPogTermListing.edges[0].node.key || data.getEPogTermListing.edges[0].node.Name)) {
      const payloadData = data.getEPogTermListing.edges[0].node.Name ?
        data.getEPogTermListing.edges[0].node.Name :
        data.getEPogTermListing.edges[0].node.key;
      dispatch({
        type: actions.ADD_PRODUCTS_INFROMATION,
        payload: {
          categoryTitle: isData && payloadData,
        },
      });
    }
  }, [data, dispatch]);

  React.useEffect(() => {
    if (productId) {
      if (!loading) {
        dispatch({
          type: actions.TOGGLE_MODAL,
          payload: {
            isModal: true,
            modalType: ModalTypes.productOverview,
            modalContent: {
              product: { id: productId },
            },
          },
        });
      }
    }
  }, [productId, loading, dispatch]);

  React.useEffect(() => {
    return () => {
      dispatch({ type: actions.REMOVE_SEARCH_FILTER });
      dispatch({ type: actions.REMOVE_PRODUCTS_SORTING });
    };
  }, [dispatch]);

  return {
    handleBrandsFilter,
    activatedFilters,
    loading,
    data,
    handleProducts,
    translation
  };
};
