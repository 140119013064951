import React from "react";

import styled from "@emotion/styled";
import { useCartHook } from "../../hooks/useCartHook";

interface ICart {
  onClick: () => void;
}

export const CartIconButton = ({ onClick }: ICart) => {
  const { totalCartValue, totalItemsInCart } = useCartHook();

  return (
    <CartStyled onClick={onClick}>
      <div>
        <span>{totalItemsInCart}</span>
      </div>
      <div>${totalCartValue}</div>
    </CartStyled>
  );
};

const CartStyled = styled.div`
  position: relative;
  min-width: 46px;
  text-align: end;
  & > div:first-of-type {
    background: url("/files/walmart/walmart-cart.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    height: 21px;

    span {
      position: absolute;
      top: -5px;
      right: -7px;
      border-radius: 50%;
      background: #edad1c;
      font-weight: bold;
      border: 2px solid transparent;
      padding: 4% 6%;
      color: #000;
      font-size: 1.2rem;
    }
  }
  & > div:last-of-type {
    font-size: 1.3rem;
    padding-top: 5px;
    color: #fff;
    margin-top: 2px;
  }
`;
