export const actions = {
  TOGGLE_MODAL: "TOGGLE_MODAL",
  TOGGLE_DIMMER: "TOGGLE_DIMMER",
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  ADD_SEARCH_FILTER: "ADD_SEARCH_FILTER",
  REMOVE_SEARCH_FILTER: "REMOVE_SEARCH_FILTER",
  ADD_PRODUCTS_SORTING: "ADD_PRODUCTS_SORTING",
  REMOVE_PRODUCTS_SORTING: "REMOVE_PRODUCTS_SORTING",
  ADD_PRODUCTS_INFROMATION: "ADD_PRODUCTS_INFROMATION",
  CHECKOUT: 'CHECKOUT',
};
