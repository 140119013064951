import styled from "@emotion/styled";
import React from "react";
import { CartIconButton } from "../../../CartIconButton";
import { HeaderStyled } from "../../styles";
import { useHeader } from "../../useHeader";

export const ItemsHeader = () => {
  const { handleCartIconClick } = useHeader();

  return (
    <HeaderStyled>
      <ItemsHeaderStyled>
        <span>My Items</span>
        <div className="cart-icon">
          <CartIconButton onClick={handleCartIconClick} />
        </div>
      </ItemsHeaderStyled>
    </HeaderStyled>
  );
};

const ItemsHeaderStyled = styled.div`
  padding: 27px 0 10px;
  min-height: 67px;
  text-align: center;
  position: relative;
  span {
    color: #fff;
    font-size: 1.8rem;
    font-weight: 600;
  }
  .cart-icon {
    position: absolute;
    right: 25px;
    top: 16px;
  }
`;
