import React from "react";

import styled from "@emotion/styled";
import { sectionPadding, sectionRadius } from "../../styled";
import { DeliveryDate } from "../../../DeliveryDate";
import { useLocalization } from "../../../../hooks/useLocalization";

export const ItemsCartHeader = () => {
  const { translation } = useLocalization();
  return (
    <CartHeaderStyled>
      <div className="shipping-address">
        <strong>{translation?.getLocalization?.Shipping || "Shipping"}</strong> {translation?.getLocalization?.To || "to"} <u>10541</u>
      </div>
      <DeliveryDate />
      <div className="line-break"></div>
      <div className="want-soon">
        <span>{translation?.getLocalization?.WantItAsSoonAsToday || "Want it as soon as today?"}</span>
        <u>{translation?.getLocalization?.SwitchToPickup || "Switch to pickup"}</u>
      </div>
    </CartHeaderStyled>
  );
};

const CartHeaderStyled = styled.div`
  padding: ${sectionPadding};
  margin-bottom: 10px;
  border-top-left-radius: ${sectionRadius};
  border-top-right-radius: ${sectionRadius};
  background: #e8f1fb;
  .shipping-address {
    font-size: 1.7rem;

    strong {
      font-size: 1.7rem;
    }
  }
  .want-soon {
    font-size: 1.3rem;
    display: flex;
    justify-content: space-between;
  }
`;
