import { useQuery } from "@apollo/client";
import { LIST_ROOT_CATEGORIES } from "../queries";

export const useCategoriesHook = ({
  numberOfItems,
  harvestId,
}: {
  harvestId?: string;
  numberOfItems?: number;
}) => {
  const { data, loading } = useQuery(LIST_ROOT_CATEGORIES, {
    variables: {
      fullpath: `/ePog Product Data/Terms/Walmart/Category/${harvestId}`,
      first: numberOfItems,
    },
  });

  const categories = data?.getObjectFolder?.children;

  return { categories, categoriesLoading: loading };
};
