import React from 'react';

import { useQuery } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GET_HARVEST_TERMS } from '../../queries';

const WALMART_MOBILE_SAVED_SEARCHES = 'WALMART_MOBILE_SAVED_SEARCHES';

export const useHeader = () => {
  const savedSearchesLocalStorage = localStorage.getItem(
    WALMART_MOBILE_SAVED_SEARCHES
  );

  const navigate = useNavigate();
  const location: any = useLocation();
  const { storeId, harvestId } = useParams();

  const [isSearchBarFocused, setIsSearchBarFocused] =
    React.useState<boolean>(false);
  const [searchHistory, setSearchHistory] = React.useState<string[]>([]);
  const [searchSuggestions, setSearchSuggestions] = React.useState<string[]>(
    []
  );
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');

  const { data: suggestions, loading } = useQuery(GET_HARVEST_TERMS, {
    variables: {
      fullpath: `/ePog Product Data/Terms/Walmart/SERP/${harvestId}`,
    },
  });

  const onSearchInputSubmit = (e: any) => {
    e.target && e.preventDefault();

    // Function is used on
    //form submit when user click enter >>> event >>> string
    //user clicks on suggestion >>> term object
    //user clicks on term from history >>> string

    const searchInput = e.target ? e.target.searchBar.value : e.key ? e.key : e;

    const exists = searchHistory.find(
      (item: any) => item.toLowerCase() === searchInput.toLowerCase()
    );

    const searchesToSave = [...searchHistory, searchInput];

    if (!exists) {
      setSearchHistory(searchesToSave);
      localStorage.setItem(
        WALMART_MOBILE_SAVED_SEARCHES,
        JSON.stringify(searchesToSave)
      );
    }
    setIsSearchBarFocused(false);
    const url = (storeId ? `/${storeId}/${harvestId}` : `/${harvestId}`);

    if (e.key) {
      navigate(`${url}/search/${e.id}__${e.key.replaceAll(' ', '_')}`, {
        replace: true,
      });
    } else {
      const term: any = searchSuggestions.find(
        (item: any) => item.key.toLowerCase() === searchInput.toLowerCase()
      );

      term
        ? navigate(
            `${url}/search/${term?.id}__${term?.key.replaceAll(
              ' ',
              '_'
            )}`,
            {
              replace: true,
            }
          )
        : navigate(`${url}/search/sk=${searchInput}`, {
            replace: true,
          });
    }
  };

  const onSearchInputChange = (e: any) => {
    setSearchKeyword(e.target.value);
  };

  const handleCartIconClick = () => {
    const url = (storeId ? `/${storeId}/${harvestId}` : `/${harvestId}`);
    navigate(`${url}/cart`);
  }

  const handleNavigateToSearchMenu = () => {
    // const url = (storeId ? `/${storeId}/${harvestId}` : `/${harvestId}`);
    // navigate(`${url}/search-menu`, {
    //   state: {
    //     isFocusInput: true,
    //   },
    // });
  }

  const handleSearchHistory = () => {
    if (searchKeyword.length > 0) {
      return searchHistory
        .filter((search: any) =>
          search.toLowerCase().includes(searchKeyword.toLowerCase())
        )
        .slice(0, 5);
    } else {
      const arrayCopy = [...searchHistory];
      return arrayCopy.reverse().slice(0, 10);
    }
  };

  React.useEffect(() => {
    savedSearchesLocalStorage
      ? setSearchHistory(JSON.parse(savedSearchesLocalStorage))
      : setSearchHistory([]);
  }, [savedSearchesLocalStorage]);

  React.useEffect(() => {
    const isSearchBar = location.state?.isSearchBar;
    if (isSearchBar) {
      setIsSearchBarFocused(true);
    }
  }, [location]);

  React.useEffect(() => {
    if (!loading) {
      let array: any = [];

      const flatChildren = (arr: any) =>
        arr?.forEach((item: any) => {
          array.push(item);
          if (item?.children?.length) {
            flatChildren(item?.children);
          }
        });

      flatChildren(suggestions?.getHarvest?.children);

      const suggestedTerms = array
        .map((item: any) => item)
        .filter((item: any) =>
          item?.key?.toLowerCase()?.includes(searchKeyword?.toLowerCase())
        );

      setSearchSuggestions(suggestedTerms);
    }
  }, [searchKeyword, loading, suggestions]);

  return {
    isSearchBarFocused,
    setIsSearchBarFocused,
    onSearchInputSubmit,
    searchHistory: handleSearchHistory(),
    handleCartIconClick,
    onSearchInputChange,
    searchSuggestions,
    handleNavigateToSearchMenu,
  };
};
