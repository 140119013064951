import React from "react";

import { useLocation } from "react-router-dom";
import { navLinks } from "./navLinks";

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    const content = document.querySelector(".content");
    if (content) {
      content.scrollTop = 0;
    }

    const isNavLink = navLinks.find((link) =>
      pathname === link.url || pathname === "/cart" ? true : false
    );

    if (isNavLink) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};
