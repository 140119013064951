import React from "react";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "@emotion/styled";

type Tcolors = string | (() => string);

interface IProps {
  title: string;
  onClick: () => void;
  isExpand?: boolean;
  addinitonalIcon?: React.ReactNode;
  colors?: Tcolors;
}

export const Chip = ({
  title,
  onClick,
  isExpand,
  addinitonalIcon,
  colors,
}: IProps) => {
  return (
    <ChipStyled onClick={onClick} colors={colors}>
      {addinitonalIcon && (
        <span className="additional-icon">{addinitonalIcon}</span>
      )}
      {title}
      {isExpand && <FontAwesomeIcon className="expand" icon={faChevronDown} />}
    </ChipStyled>
  );
};

const ChipStyled = styled.span<{ colors?: Tcolors }>`
  font-size: 1.5rem;
  font-weight: 300;
  background: ${({ colors }) => (colors === "dark" ? "#000" : "#efeff0")};
  color: ${({ colors }) => (colors === "dark" ? "#fff" : "#000")};
  border-radius: 20px;
  padding: 10px 20px;
  margin: 0 5px;
  display: inline-flex;
  align-items: center;

  .additional-icon {
    margin-right: 6px;
    font-size: 1.1rem;
  }
  .expand {
    margin-left: 7px;
  }
`;
