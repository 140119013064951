import styled from "@emotion/styled";

export const ProductsStyled = styled.div<{ isModal: boolean }>`
  padding: 5px 15px 10px;
  overflow: hidden;
  .video{
    margin-left:auto;
    margin-right:auto;
    video {
      width: 100%;
      padding: 10px 0 0 0;
    }
  }

  .products-list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow: ${({ isModal }) => (isModal ? "hidden" : "auto")};
  }

  .fullsize {
    width: 100%;

    grid-column: 1 / span 4;

     @media (max-width: 1140px) {
      grid-column: auto;
    }
    @media (max-width: 768px) {
      grid-column: 1 / span 2;
    }
    @media (max-width: 550px) {
      grid-column: 1 / span 1;
    }
  }
    
  video {
    width: 100%;
    padding:26px 0 0 0;
  }
  @media (min-width:550px){
    .video {
      width: 23%
    }
  }
`;

export const ProductHeader = styled.h2`
  font-size: 1.5rem;
  letter-spacing: 0.3px;
  font-weight: 700;
  padding: 15px;
  span {
    font-weight: 400;
    font-size: 1.3rem;

    color: gray;
  }
`;
