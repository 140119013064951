import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Header, HeaderVariants } from "../../components/Header";
import {
  faChevronDown,
  faChevronUp,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonVariants } from "../../components/Button";
import { AnimatePresence, motion } from "framer-motion";
import { AccountRouteStyled } from "./styles";

export const AccountRoute = () => {
  const [isSubscription, setIsSubscription] = React.useState(false);
  return (
    <AccountRouteStyled>
      <Header variant={HeaderVariants.account} />
      <div className="content">
        <div className="background"></div>
        <div className="purchase-history">
          <div>
            <FontAwesomeIcon icon={faFileAlt} />
            <span>Purchase history</span>
          </div>
          <div>
            <span>Track your order status</span>
            <Button
              variant={ButtonVariants.addTransparent}
              content={{ text: "Track order" }}
              onClick={() => {}}
            />
          </div>
        </div>
        <motion.div
          animate={{ height: isSubscription ? 87 : 57 }}
          className="subscriptions"
        >
          <div>
            <span>Subscriptions & plans</span>
            {!isSubscription ? (
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={() => setIsSubscription(!isSubscription)}
              />
            ) : (
              <FontAwesomeIcon
                icon={faChevronUp}
                onClick={() => setIsSubscription(!isSubscription)}
              />
            )}
          </div>
          <AnimatePresence>
            {isSubscription && (
              <motion.div
                animate={{ y: 0, marginTop: 20 }}
                initial={{ y: -100, marginTop: 0 }}
                exit={{ y: -100, marginTop: 0 }}
              >
                Walmart+
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
        <div className="your-thoughts">
          <p>We'd love to hear what you think!</p>
          <Button
            variant={ButtonVariants.addTransparent}
            content={{ text: "Give feedback" }}
            onClick={() => {}}
          />
        </div>
        <ul className="list">
          <li>Help</li>
          <li>Terms of Use</li>
          <li>Privacy & Security</li>
          <li>CA Privacy Rights</li>
          <li>Do Not Sell My Personal Information</li>
          <li>Request My Personal Information</li>
        </ul>

        <p className="rights-reserved">© 2022 Walmart. All Rights Reserved.</p>
      </div>
    </AccountRouteStyled>
  );
};
