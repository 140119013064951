import styled from "@emotion/styled";

interface Iprops {
  styles?: string;
  isPressed?: boolean;
}

export const ButtonStyles = styled.button<Iprops>`
  background: ${({ styles }) =>
    styles === "transparent" ? "#fff" : "#2f69d3"};
  border: ${({ styles }) =>
    styles === "transparent" ? "1px solid #b4b5b8" : "none"};
  color: ${({ styles }) => (styles === "transparent" ? "#000" : "#fff")};
  padding: ${({ styles }) =>
    styles === "transparent" ? "6px 10px" : "9px 10px"};
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;

  & > div,
  & > span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .button-content {
    /* color: ${({ styles }) =>
      styles === "transparent" ? "#b4b5b8" : "#6ab7ea"}; */
    margin: ${({ isPressed }) => isPressed && "0 8px"};
    padding: 0 10px;
  }
`;
