import React from "react";
import styled from "@emotion/styled";

export const TransparentButton = ({ onClick, content }: any) => {
  return <ButtonStyled onClick={onClick}>{content}</ButtonStyled>;
};

const ButtonStyled = styled.button`
  border-radius: 15px;
  padding: 5px 10px;
  background: transparent;
  border-width: 1px;
  font-size: 1.15rem;
  font-weight: 600;
`;
