import React from "react";

import styled from "@emotion/styled";

export const DeliveryDate = () => {
  const date = new Date();
  const newDate =
    date && new Date(date.setDate(date.getDate() + 5)).toString().split(" ");
  return (
    <DeliveryDateStyled>
      {" "}
      {newDate && (
        <div className="arrives-by">
          Arrives by
          <strong>
            {" "}
            {newDate[0]}, {newDate[1]} {newDate[2]}
          </strong>
        </div>
      )}
    </DeliveryDateStyled>
  );
};

const DeliveryDateStyled = styled.div`
  display: inline-block;
  .arrives-by {
    margin: 20px 0 10px;
    font-size: 1.3rem;
  }
`;
