import React from "react";

import { AnimatePresence, motion } from "framer-motion";

import { EmptyCard } from "../../components/EmptyCart";
import { Header, HeaderVariants } from "../../components/Header";
import { FilledCart } from "../../components/FilledCart";
import { useAppContext } from "../../context";
import { useCartHook } from "../../hooks/useCartHook";

export const CartRoute = () => {
  const { handleCart, checkout, totalCartValue, totalItemsInCart, checkoutMessage } = useCartHook();
  const {
    value: { cart },
  } = useAppContext();
  const useCartHookProps = { totalItemsInCart, totalCartValue, handleCart, checkout };

  return (
    <AnimatePresence>
      <motion.div
        animate={{ x: 0, y: 0, height: "fit-content" }}
        exit={{ x: "100vw", y: 0 }}
        initial={{ x: "100vw", y: 0 }}
        transition={{ ease: "easeIn", duration: 0.1 }}
      >
        <Header variant={HeaderVariants.cart} />

        {cart.length ? <FilledCart {...useCartHookProps}/> : <EmptyCard checkoutMessage={checkoutMessage}/>}
      </motion.div>
    </AnimatePresence>
  );
};
