import styled from "@emotion/styled";

export const productOverviewFont = "1.3rem";
export const productOverviewTitleFont = "1.35rem";
export const productOverviewPadding = "18px";

export const ProductOverviewModalStyled = styled.div`
  font-size: ${productOverviewFont};
  .line-break {
    height: 1px;
    width: 100%;
    background-color: #cacbce;
  }
  .more-sellers {
    padding: ${`${productOverviewPadding} ${productOverviewPadding} 20px`};
    strong {
      font-size: 1.2rem;
    }
    p:first-of-type {
      margin-top: 15px;
    }
    p:last-of-type {
      margin-top: 10px;
    }
  }

  .unusual-activity {
    padding: ${productOverviewPadding};
    svg {
      margin-right: 7px;
    }
    u {
      margin-left: 5px;
    }
  }

  .feedback {
    margin: 10px;
    text-align: center;
    u {
      margin-left: 4px;
    }
  }

  .footer {
    position: sticky;
    padding: 0 15px;
    bottom: 0;
    height: 70px;
    background: #fff;
    z-index: 5;
    border-top: 1px solid #cacbce;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 1.8rem;
      font-weight: 700;
      .oldPrice {
        font-size: 15px;
        color: #999999;
        text-decoration: line-through
      }
    }
    button {
      font-size: 1.8rem;
      font-weight: 600;
    }
  }
`;
