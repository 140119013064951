import { useCartHook } from '../../hooks/useCartHook';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../context';
import { actions } from '../../context/actions';
import { ModalTypes } from '../Modals/types';
import { IProduct } from '../../types';
import { useCategoryNameHook } from '../../hooks/useCategoryNameHook';
import { normalizeNameForUrl, sendMessageWithUrl } from '../../helpers';
import { useLocalization } from '../../hooks/useLocalization';

export const useProduct = ({
  product,
  position,
}: {
  product: IProduct;
  position?: number;
}) => {
  const { name } = useCategoryNameHook();
  const { handleCart } = useCartHook();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { dispatch } = useAppContext();
  const { harvestId, termId, productId, storeId, categoryId } = useParams();
  const { translation } = useLocalization();

  const logMessage = () => {
    if (!product) return

    // const { categoryId, storeId, harvestId } = url ? url : { categoryId: '', storeId: '', harvestId: '' };
    // const product = contnt.eproduct;
    const price = product?.Price != 0 ? product?.Price : product?.RegularPrice;
    const brand = product?.Brand ? product?.Brand : '';
    let promoCode = (product.URLPromoCode != null) ? ('__promoCode-' + product.URLPromoCode) : '__promoCode-';

    let productUrl = (storeId ? `/${storeId}/${harvestId}` : `/${harvestId}`);
    productUrl += (categoryId ? `/ip/${categoryId}` : `/ips`);
    productUrl += (`/${product?.id}__${brand}__${normalizeNameForUrl(product?.Name)}_${price}_${promoCode}`);
    sendMessageWithUrl(productUrl);
  }
  const handleProductModal = () => {
    logMessage();
    // used on pages other than search related

    if (
      pathname.includes('services') ||
      !pathname.split('/')[3] ||
      pathname.split('/')[3] === ''
    ) {
      dispatch({
        type: actions.TOGGLE_MODAL,
        payload: {
          isModal: true,
          modalContent: { product, route: true },
          modalType: ModalTypes.productOverview,
        },
      });
    } else {
      const price = product.RegularPrice ? product.RegularPrice : product.Price;
      if (termId && productId) {
        const url = (storeId ? `/${storeId}/${harvestId}` : `/${harvestId}`);
        const termName = termId.includes('__') ? '' : `__${name}`;
        //used for similar products carousel in product overview modal
        navigate(`${url}/search/${termId}${termName}/${product.id}`);
        //used in category or serp
      } else {
        navigate(
          `./${product.id}__${product.key.replaceAll(' ', '_')}_${price.toFixed(
            2
          )}_${position}`
        );
      }
    }
  };

  return {
    handleCart,
    handleProductModal,
    translation
  };
};
