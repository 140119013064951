import React from "react";

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { productOverviewPadding, productOverviewTitleFont } from "../../styles";

import parse from "html-react-parser";

import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { useLocalization } from "../../../../../../hooks/useLocalization";

export const ProductDetails = ({ details }: any) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const icon = isOpen ? faChevronDown : faChevronUp;
  const { translation } = useLocalization();

  React.useEffect(() => {
    const swiperElements = document.querySelectorAll('.product-details-swiper');
    swiperElements.forEach((element) => {
      const swiperElement = element as HTMLElement;
      new SwiperCore(swiperElement, {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        modules: [Pagination, Navigation],
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    });
  }, [details]);


  return (
    <ProductDetailsStyled>
      <div className="product-details-header">
        <span>
          <strong>{translation?.getLocalization?.ProductDetails || "Product details"}</strong>
        </span>
        <FontAwesomeIcon onClick={() => setIsOpen(!isOpen)} icon={icon} />
      </div>
      <div className="product-details-content-container">
        <AnimatePresence>
          {isOpen && (
            <motion.div
              animate={{ y: 0 }}
              exit={{ y: -500 }}
              initial={{ y: -500 }}
              transition={{ ease: "linear", duration: 0.2 }}
              className="product-detals-content"
            >
              <DescriptionStyled>{parse(details)}</DescriptionStyled>
              <u>{translation?.getLocalization?.MoreDetails || "More details"}</u>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </ProductDetailsStyled>
  );
};

const DescriptionStyled = styled.div`
.swiper {
  min-height:100px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    & > div:nth-child(2) {
      position: absolute;
      left: 5%;
      max-width: 50%;
      padding: 10px;
      background: #000000bd;
      color:white;
      * {
        margin:unset;
        color:white;
      }
    }
  }

  @media screen and (max-width:768px){
    .swiper-slide {
      text-align: center;
      display: flex;
      flex-direction:column;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      & > div:nth-child(n){
        position: static;
        max-width: unset;
        padding: 10px;
        background: none;
        color:black;
      }
    }
    .swiper-pagination{
      position:static;
    }
  }
}
`
const ProductDetailsStyled = styled.div`
  padding: ${`${productOverviewPadding} ${productOverviewPadding} 20px`};
  .product-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: ${productOverviewTitleFont};
    }
    svg {
      font-size: 1.6rem;
    }
  }
  .product-detals-content {
    margin-top: 15px;
    padding: 30px 20px 10px 15px;
    border-radius: 5px;
    border: 1px solid #eaeaeb;
    border-bottom-width: 2px;
    & > div {
      margin-bottom: 10px;
      padding: 10px;
      line-height: 1.5;
      overflow: auto;
    }
    p,
    u {
      line-height: 1.3;
      font-size: 1.3rem;
      margin-bottom: 15px;
      @media (max-width:768px){
        margin-bottom: 0;
      }
    }
    table {
      width: 100%;
      height: 100%;
      margin: auto;
      display: block;
      overflow-y: hidden;
      border-spacing: 20px 0;
    }
    p>img{
      max-width:100%;
    }
  }
`;
