import React from 'react';

import styled from '@emotion/styled';

export const SquareFilters = ({
  notClickableBrands,
  clickableBrands,
  onFilterClick,
  activatedFilters,
}: {
  notClickableBrands: string[];
  clickableBrands: string[];
  onFilterClick: (arg: string, bool: boolean) => void;
  activatedFilters: any;
}) => {
  const handleClick = (brand: string, bool: boolean) => {
    onFilterClick(brand, bool);
  };

  return (
    <SquareFiltersStyled>
      {clickableBrands.map((brand, key) => {
        const isActive =
          activatedFilters &&
          activatedFilters.find((filter: any) => filter === brand);
        return (
          <span
            key={key}
            onClick={() =>
              isActive ? handleClick(brand, false) : handleClick(brand, true)
            }
            className={isActive && 'active'}
          >
            {brand}
          </span>
        );
      })}
      {notClickableBrands.map((brand, key) => {
        const isActive =
          activatedFilters &&
          activatedFilters.find((filter: any) => filter === brand);
        return (
          brand?.length && (
            <span key={key} className={isActive && 'active'}>
              {brand}
            </span>
          )
        );
      })}
    </SquareFiltersStyled>
  );
};

const SquareFiltersStyled = styled.div`
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 16px 0;
  scrollbar-width: none;

  .active {
    color: #fff;
    background-color: #000;
  }

  span {
    border: 1px solid #e5e5e5;
    padding: 12px 16px;
    margin: 0 4px;
    font-size: 1.4rem;
  }
`;
