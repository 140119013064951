import React from "react";

import { useAppContext } from "../../context";
import { actions } from "../../context/actions";
import { useCartHook } from "../../hooks/useCartHook";
import { IProduct } from "../../types";
import { Button, ButtonVariants } from "../Button";
import { ModalTypes } from "../Modals/types";
import { CartItem } from "./components/CartItem";
import { ItemsCartHeader } from "./components/ItemsCartHeader";
import { CartStyled } from "./styled";
import { useParams } from "react-router-dom";
import { setWindowVars } from "../../helpers";
import { useLocalization } from "../../hooks/useLocalization";

interface IFilledCartProps {
  totalItemsInCart: any,
  totalCartValue: any,
  handleCart: any,
  checkout: any,
}

export const FilledCart = ({ totalItemsInCart, totalCartValue, handleCart, checkout }: IFilledCartProps) => {
  const {
    value: { cart },
    dispatch,
  } = useAppContext();
  const { translation } = useLocalization();

  const urlParams = useParams();

  const handleProductClick = (product: IProduct) => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: true,
        modalContent: { product, route: true },
        modalType: ModalTypes.productOverview,
      },
    });
  };

  const handleRemove = (product: IProduct) => {
    dispatch({
      type: actions.REMOVE_FROM_CART,
      payload: { product, type: "REMOVE_ALL" },
    });
  };

  React.useEffect(() => {
    if (window.finishIt == true) {
      checkout('checkout', cart);
      setWindowVars();
    }
  }, [window.finishIt])

  return (
    <CartStyled>
      <div className="cart-container">
        <ItemsCartHeader />
        {cart.map((item: IProduct, index: number) => (
          <CartItem
            urlParams={urlParams}
            item={item}
            key={index}
            onRemove={(e: any) => {
              e.stopPropagation();
              handleRemove(item);
            }}
            onPriceButtonClick={handleCart}
            onClick={() => handleProductClick(item)}
          />
        ))}
      </div>
      <div
        className="line-break
      "
      ></div>

      <div className="subtotal">
        <span>{translation?.getLocalization?.Subtotal || "Subtotal"} ({totalItemsInCart} {translation?.getLocalization?.Items || "items"})</span>
        <span>${totalCartValue}</span>
      </div>

      <div className="line-break"></div>

      <div className="estimated-total">
        <span>{translation?.getLocalization?.EstimatedTotal || "Estimated total"}</span>
        <span>${totalCartValue}</span>
      </div>

      <div className="line-break"></div>

      <div className="cart-footer">
        <div>{translation?.getLocalization?.EstimatedTotal || "Estimated total"} ${totalCartValue}</div>
        <Button
          onClick={() => { checkout('checkout', cart); }}
          variant={ButtonVariants.blueGeneric}
          content={translation?.getLocalization?.CheckoutAllItems || "Checkout all items"}
        />
      </div>
    </CartStyled>
  );
};
