import React from "react";

import styled from "@emotion/styled";
import { useLocalization } from "../../hooks/useLocalization";

export const PopularServices = () => {
  const {translation}=useLocalization();
  

  const services = [
    {
      name: translation?.getLocalization?.Pharmacy || "Pharmacy",
      img: "/files/walmart/services/auto.png",
    },
    {
      name: translation?.getLocalization?.AutoCareCenter || "Auto Care Center",
      img: "/files/walmart/services/medicine.png",
    },
    {
      name: translation?.getLocalization?.CustomCakes || "Custom Cakes",
      img: "/files/walmart/services/cakes.png",
    },
  ];
  return (
    <PopularServicesStyled>
      <div>
        <span>{translation?.getLocalization?.PopularServices || "Popular services"}</span>
        <u>{translation?.getLocalization?.ViewAll || "View all"}</u>
      </div>
      <div>
        {services.map((service, key) => (
          <div key={key}>
            <img src={service.img} alt={service.name} />
            <p>{service.name}</p>
          </div>
        ))}
      </div>
    </PopularServicesStyled>
  );
};

const PopularServicesStyled = styled.div`
  margin: 15px;
  & > div:first-of-type {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    span {
      font-size: 1.65rem;
      font-weight: 600;
    }
    u {
      font-size: 1.4rem;
    }
  }
  & > div:last-of-type {
    display: flex;
    justify-content: center;
    gap: 16px;
    & > div {
      width: 30%;
      text-align: center;
      padding: 15px 15px 25px;
      margin-top: 20px;
      border-radius: 10px;
      border: 1px solid #e5e5e5;
      border-bottom-width: 2px;
      @media (max-width: 330px) {
        padding: 5px;
      }
      img {
        max-height: 85px;
        width: 100%;
        object-fit: contain;
      }
      p {
        font-size: 1.3rem;
        margin-top: 5px;
        line-height: 1.5;
        @media (max-width: 330px) {
          font-size: 1rem;
        }
      }
    }
  }
`;
