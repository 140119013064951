import { IProduct } from '../types';
import { actions } from './actions';

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case actions.TOGGLE_MODAL:
      state.isModal = action.payload.isModal;
      state.modalContent = action.payload && action.payload.modalContent;
      state.modalType = action.payload && action.payload.modalType;
      break;

    case actions.TOGGLE_DIMMER:
      state.isDimmer = action.payload.isDimmer;
      break;

    case actions.ADD_TO_CART:
      const itemToAddExists = state.cart.find(
        (item: IProduct) => item.id === action.payload.id
      );

      if (itemToAddExists) {
        if (itemToAddExists.amount !== 12) {
          itemToAddExists.amount = itemToAddExists.amount + 1;
        }
      } else {
        state.cart = [...state.cart, { ...action.payload, amount: 1 }];
      }
      break;

    case actions.REMOVE_FROM_CART:
      const itemToRemove = state.cart.find(
        (item: IProduct) => item.id === action.payload.product.id
      );

      if (action.payload.type === 'REMOVE_ONE') {
        if (itemToRemove.amount === 1)
          state.cart = state.cart.filter(
            (item: IProduct) => item.id !== action.payload.product.id
          );

        if (itemToRemove.amount > 1)
          itemToRemove.amount = itemToRemove.amount - 1;
      }

      if (action.payload.type === 'REMOVE_ALL') {
        state.cart = state.cart.filter(
          (item: IProduct) => item.id !== action.payload.product.id
        );
      }

      break;

    case actions.ADD_PRODUCTS_SORTING:
      const existingSorting = state.productsSorting.find(
        (filter: any) => filter.name === action.payload.name
      );
      if (!existingSorting) {
        state.productsSorting.push(action.payload);
      }

      if (existingSorting) {
        state.productsSorting = state.productsSorting.filter(
          (filter: any) => filter.name !== action.payload.name
        );
        state.productsSorting.push(action.payload);
      }
      break;

    case actions.REMOVE_PRODUCTS_SORTING:
      state.productsSorting = [];
      break;

    case actions.ADD_SEARCH_FILTER:
      const existingFilter = state.searchFilters.find(
        (filter: any) => filter.name === action.payload.name
      );
      if (!existingFilter) {
        state.searchFilters.push(action.payload);
      }

      if (existingFilter) {
        state.searchFilters = state.searchFilters.filter(
          (filter: any) => filter.name !== action.payload.name
        );
        state.searchFilters.push(action.payload);
      }

      break;

    case actions.REMOVE_SEARCH_FILTER:
      if (!action.payload) {
        state.searchFilters = [];
      }

      if (action.payload) {
        const clearFilters = state.searchFilters.filter(
          (filter: any) => filter.name !== action.payload.name
        );
        clearFilters.push(action.payload);
        state.searchFilters = clearFilters;
      }

      break;

    case actions.ADD_PRODUCTS_INFROMATION:
      state.productsInformation.categoryTitle = action.payload.categoryTitle;
      break;

    case actions.CHECKOUT:
      const itemsInCart = state.cart.find(
        (item: IProduct) => item.id !== action.payload.product.id
      );
      state.cart = state.cart.filter(
        (item: IProduct) => item.id !== action.payload.product.id
      );
      break;

    default:
      return state;
  }
};
