import React from 'react';
import { IProduct } from '../../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FilterModalCommonStyles } from '../../commonStyles';
import { FilterModalButtons } from '../../../FilterModalButtons';
import { SpecialOffersStyled } from './styles';
import { useSpecialOffersFilter } from './useSpecialOffersFilter';

export const SpecialOffersFilterModal = ({
  productsBeforeFiltering,
  buttonsOff,
}: any) => {
  const {
    searchBar,
    setSearchBarInput,
    handleSubmit,
    handleClear,
    handleCheckboxes,
    checked,
    translation
  } = useSpecialOffersFilter({ productsBeforeFiltering });


  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      <SpecialOffersStyled>
        <div>
          <h2>{translation?.getLocalization?.SpecialOffersAreYouInterestedIn || "What special offers are you interested in?"}</h2>
          <div className="search-bar">
            <FontAwesomeIcon icon={faSearch} />
            <input
              value={searchBar}
              onChange={(e) => setSearchBarInput(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faTimesCircle}
              onClick={() => setSearchBarInput('')}
            />
          </div>

          <div className="checkboxes">
            <FormGroup>
              {productsBeforeFiltering ? (
                productsBeforeFiltering
                  .filter(
                    (product: IProduct, index: number, arr: IProduct[]) =>
                      product.PromoType?.toLowerCase().includes(
                        searchBar.toLowerCase()
                      ) &&
                      index ===
                      arr.findIndex((item) => item.PromoType === product.PromoType)
                  )
                  .map((product: IProduct, index: number) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          name={product.PromoType}
                          checked={checked[product.PromoType]}
                          onChange={handleCheckboxes}
                        />
                      }
                      label={product.PromoType}
                    />
                  ))
              ) : (
                <div></div>
              )}
            </FormGroup>
          </div>
        </div>
        <div className="buttons">
          <FilterModalButtons onSubmit={handleSubmit} onClear={handleClear} />
        </div>
      </SpecialOffersStyled>
    </FilterModalCommonStyles>
  );
};
