import styled from "@emotion/styled";
import { motion } from "framer-motion";
import React from "react";
import { useAppContext } from "../../context";
import { actions } from "../../context/actions";

export const TransitionDimmer = () => {
  const {
    dispatch,
    value: { isDimmer },
  } = useAppContext();

  React.useEffect(() => {
    setTimeout(() => {
      dispatch({ type: actions.TOGGLE_DIMMER, payload: { isDimmer: false } });
    }, 100);
  }, [dispatch, isDimmer]);

  return (
    isDimmer && (
      <DimmerStyled
        animate={{ opacity: 0 }}
        transition={{ duration: 0.1 }}
      ></DimmerStyled>
    )
  );
};

const DimmerStyled = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  opacity: 1;
  background-color: gray;
  z-index: 2;
`;
