import React from 'react';

import styled from '@emotion/styled';

import { Product } from '../../../../../Product';
import { productOverviewPadding } from '../../styles';
import { IProduct } from '../../../../../../types';
import { useParams } from 'react-router-dom';
import { useLocalization } from '../../../../../../hooks/useLocalization';

export const ProductsSlider = ({
  products,
  title,
  subTitle,
  sponsored,
}: any) => {

  const urlParams = useParams();
  const {translation}=useLocalization();

  return (
    <SimilarProductsStyled sponsored={sponsored}>
      <p>
        <span className="title">{title}</span>
        {sponsored ? <span>{translation?.getLocalization?.Sponsored || "Sponsored"}</span> : ''}
      </p>
      <p>{subTitle ? subTitle : ''}</p>
      <div className="products-scroller">
        {products &&
          products.map((product: IProduct, i: number) => (
            <Product product={product} key={i} urlParams={urlParams}/>
          ))}
      </div>
    </SimilarProductsStyled>
  );
};

interface IStyledProps {
  sponsored: boolean;
}

const SimilarProductsStyled = styled.div<IStyledProps>`
  padding: ${`${productOverviewPadding} ${productOverviewPadding} 20px`};
  p:first-of-type {
    display: ${({ sponsored }) => (sponsored ? 'flex' : 'block')};
    justify-content: ${({ sponsored }) => (sponsored ? 'space-between' : '')};
    span {
      margin-left: auto;
      font-size: 1rem;
    }
    .title {
      font-size: 1.65rem;
      font-weight: 600;
    }
  }
  p:last-of-type {
    font-size: 0.97rem;
    margin-top: 10px;
  }
  .products-scroller {
    margin-top: 15px;
    display: flex;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    gap: 20px;
    & > div {
      min-width: 40%;
    }
  }
  .name {
    height: 25px;
  }
`;
