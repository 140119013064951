import React from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '@emotion/styled';

type Tcolors = string | (() => string);

interface IProps {
  title: string;
  onClick: () => void;
  isExpand?: boolean;
  addinitonalIcon?: React.ReactNode;
  colors?: Tcolors;
  background: Tcolors;
  backgroundImage: string;
}

export const ChipWithImage = ({
  title,
  onClick,
  isExpand,
  addinitonalIcon,
  colors,
  background,
  backgroundImage
}: IProps) => {
  return (
    <ChipStyledWithImage onClick={onClick} colors={colors} background={background} backgroundImage={backgroundImage}>
      <div className="filterContainer">
        <div className="filterImage"></div>
        {title}
      </div>
      {isExpand && <FontAwesomeIcon className="expand" icon={faChevronDown} />}
    </ChipStyledWithImage>
  );
};

const ChipStyledWithImage = styled.span<{ colors?: Tcolors; background?: Tcolors; backgroundImage: string; }>`
  border: ${({ background, colors }) =>
    colors === 'dark' ? '1px solid #DBDBDB' : '1px solid #FFF'};
  color: #000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  cursor: pointer;

  .filterContainer {
    width: 100%;
    text-align: center;
  }

  .filterImage {
    background-image: url('${({ backgroundImage }) =>
    backgroundImage === '' ? '' : backgroundImage}');
    background-size: contain;
    width: 150px;
    height: 150px;
    margin: 0 0 5px 0;
    @media (max-width:768px){
      width: 95px;
      height: 95px;
    }
  }

  .additional-icon {
    display: flex;
    align-items: center;
    margin-right: 6px;

    div {
      display: flex;
      align-items: center;
    }

    svg {
      height: 15px;
      fill: #000;
      stroke: #000;
    }
  }
  .expand {
    margin-left: 7px;
  }
`;
