import React from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../context";

export const useCategoryNameHook = () => {
  const {
    value: {
      productsInformation: { categoryTitle },
    },
  } = useAppContext();

  const { termId } = useParams();

  const [name, setName] = React.useState("");

  React.useEffect(() => {
    if (termId?.includes("sk=")) {
      setName(termId?.replace("sk=", ""));
    }
    if (!termId?.includes("sk=") && categoryTitle) {
      setName(categoryTitle);
    }
    if (!termId) {
      setName("");
    }
  }, [categoryTitle, termId]);

  return { name };
};
