import React from "react";

import Radio from "@mui/material/Radio";

import styled from "@emotion/styled";

export const RadioComponent = ({ ...rest }) => {
  return <RadioStyled {...rest} />;
};

const RadioStyled = styled(Radio)`
  .MuiSvgIcon-root {
    font-size: 2.7rem;
    color: #000;
  }
`;
