import React from "react";

import { useImmerReducer } from "use-immer";

import { reducer } from "./reducer";
import { Icontext, Istate } from "./types";

const initialState: Istate = {
  isModal: false,
  isDimmer: false,
  modalType: undefined,
  modalContent: undefined,
  cart: [],
  searchFilters: [],
  productsSorting: [],
  productsInformation: {
    categoryTitle: "",
  },
};

const AppContext = React.createContext<any>(initialState);

export const ContextProvider = ({ children }: Icontext) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);

  const value = React.useMemo(
    () => ({
      ...state,
    }),
    [state]
  );

  return (
    <AppContext.Provider value={{ value, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext must be used under <ContextProvider/>");
  }
  return context;
};
