import React from "react";

import styled from "@emotion/styled";
import { Button } from "../Button";
import { useLocalization } from "../../hooks/useLocalization";

export const FilterModalButtons = ({ onSubmit, onClear }: any) => {
  const { translation } = useLocalization();
  return (
    <ComponentStyled>
      <u onClick={onClear}>{translation?.getLocalization?.Clear || 'Clear'}</u>
      <Button variant="blueGeneric" content={translation?.getLocalization?.ViewResults || 'View results'} onClick={onSubmit} />
    </ComponentStyled>
  );
};

const ComponentStyled = styled.div`
  text-align: center;
  margin-top: 10px;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding-bottom: 5%;

  u {
    font-size: 1.2rem;
    padding: 13px 0;
    display: block;
  }
  button {
    display: block;
    text-align: center;
    width: 100%;
  }
`;
