import React from 'react';
import { useAppContext } from '../../../../context';
import { actions } from '../../../../context/actions';
import { IProduct } from '../../../../types';
import { useLocalization } from '../../../../hooks/useLocalization';

export const useSpecialOffersFilter = ({
  productsBeforeFiltering,
}: {
  productsBeforeFiltering: IProduct[];
}) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();
  const {translation}=useLocalization();

  const specialOffersFilter = searchFilters.find(
    (filter: any) => filter.name === 'Special Offers'
  );

  const intitialCheckedState = productsBeforeFiltering.reduce(
    (obj: any, product: IProduct) => {
      return { ...obj, [product.PromoType]: false };
    },
    {}
  );

  const [searchBar, setSearchBarInput] = React.useState('');
  const [checked, setChecked] = React.useState<any>(
    specialOffersFilter?.promo || intitialCheckedState
  );

  const handleSubmit = () => {
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: { name: 'Special Offers', promo: checked },
    });

    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalContent: null },
    });
  };

  const handleClear = () => {
    setChecked(intitialCheckedState);
    dispatch({
      type: actions.REMOVE_SEARCH_FILTER,
      payload: {
        name: 'Special Offers',
        promo: intitialCheckedState,
      },
    });
  };

  const handleCheckboxes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  return {
    searchBar,
    setSearchBarInput,
    handleSubmit,
    handleClear,
    handleCheckboxes,
    checked,
    translation
  };
};
