import React from 'react';

import {
  faArrowUp,
  faHistory,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInput } from '../../../SearchInput';
import { useHeader } from '../../useHeader';
import { CartIconButton } from '../../../CartIconButton';
import { HeaderStyled } from '../../styles';
import { SearchHeaderStyled } from './styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTransitionDimmer } from '../../../../hooks/useTransitionDimmer';

export const SearchHeader = () => {
  const { pathname } = useLocation();
  const { storeId, harvestId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    isSearchBarFocused,
    setIsSearchBarFocused,
    onSearchInputSubmit,
    searchHistory,
    handleCartIconClick,
    onSearchInputChange,
    searchSuggestions,
  } = useHeader();

  const { toggleDimmer } = useTransitionDimmer();

  const isSearchMenu = pathname.includes('search-menu');

  const body = document.querySelector('body');
  const url = (storeId ? `/${storeId}/${harvestId}` : `/${harvestId}`);
  const [isPrevKeyDefault, setIsPrevKeyDefault] = React.useState<boolean|undefined>(false);

  const handleGoBackClick = (fallback?: string) => {
    if (location.pathname.replaceAll('%20', ' ') === `/${storeId}/${harvestId}`.replaceAll('%20', ' ')) return // if in home screen, return
    let thereIsAPrevPage = (location.key !== "default");

    if (isPrevKeyDefault !== undefined) {
      thereIsAPrevPage = thereIsAPrevPage && !isPrevKeyDefault
      setIsPrevKeyDefault(undefined)
    }

    if (thereIsAPrevPage) {
      navigate(-1);
    } else {
      navigate(fallback || `${url}/search-menu`);
    }
  }
  return (
    <HeaderStyled>
      <SearchHeaderStyled
        isSearchMenu={isSearchMenu}
        isSearchBarFocused={isSearchBarFocused}
      >
        <div className="search-bar">
          <div className="chevron-back">
            {isSearchMenu ? (
              <span></span>
            ) : (
              <FontAwesomeIcon
                icon={faChevronLeft}
                // onClick={() => navigate(`${url}/search-menu`)}
                onClick={()=>handleGoBackClick()}
              />
            )}
          </div>

          <SearchInput
            isSearchMenu={isSearchMenu}
            onFocus={() => {
              if (body) body.style.overflow = 'hidden';
              setIsSearchBarFocused(true);
              toggleDimmer();
            }}
            onSearchInputSubmit={(e) => {
              if (body) body.style.overflow = 'auto';
              onSearchInputSubmit(e);
            }}
            onSearchInputChange={onSearchInputChange}
          />

          {isSearchBarFocused ? (
            <span
              className="cancel-focus"
              onClick={() => {
                if (body) body.style.overflow = 'auto';
                setIsSearchBarFocused(false);
                toggleDimmer();
              }}
            >
              Cancel
            </span>
          ) : (
            <CartIconButton onClick={handleCartIconClick} />
          )}
        </div>

        <div className="search-history">
          {searchHistory?.slice(0, 5)?.map((search: string, index: number) => (
            <div
              className="search-history-term"
              key={index}
              onClick={() => {
                if (body) body.style.overflow = 'auto';
                onSearchInputSubmit(search);
              }}
            >
              <div>
                <FontAwesomeIcon icon={faHistory} />
                <span>{search}</span>
              </div>
              <FontAwesomeIcon icon={faArrowUp} />
            </div>
          ))}
          {searchSuggestions
            ?.slice(0, 10)
            ?.map((suggestion: any, index: number) => (
              <div
                className="search-history-term"
                key={index}
                onClick={() => {
                  if (body) body.style.overflow = 'auto';
                  onSearchInputSubmit(suggestion);
                }}
              >
                <div>
                  <span>{suggestion.key}</span>
                </div>
                <FontAwesomeIcon icon={faArrowUp} />{' '}
              </div>
            ))}
        </div>
      </SearchHeaderStyled>
    </HeaderStyled>
  );
};
