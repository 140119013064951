import React from "react";
import { ButtonStyles } from "../../commonStyles/buttonStyles";

interface Iprops {
  onClick: () => void;
  content: string;
}

export const BlueGenericButton = ({ onClick, content }: Iprops) => {
  return <ButtonStyles onClick={onClick}>{content}</ButtonStyles>;
};
