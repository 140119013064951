import React from "react";

import styled from "@emotion/styled";

import { useNavigate, useParams } from "react-router-dom";
import { Categories } from "../Categories";

export const FavoriteDepartments = ({ categories }: any) => {
  const navigate = useNavigate();
  const { storeId, harvestId } = useParams();
  const url = (storeId ? `/${storeId}/${harvestId}` : `/${harvestId}`);
  return (
    <FavoriteDepartmentsStyled>
      <div>
        <span>Your favorite departments</span>
        <u onClick={() => navigate(`${url}/search-menu`)}>View all</u>
      </div>
      <div className="categories">
        <Categories categories={categories} />
      </div>
    </FavoriteDepartmentsStyled>
  );
};

const FavoriteDepartmentsStyled = styled.div`
  margin: 15px;
  & > div:first-of-type {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 1.65rem;
      font-weight: 600;
    }
    u {
      font-size: 1.4rem;
    }
  }
`;
