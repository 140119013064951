import React from "react";

import { AccountHeader } from "./variants/AccountHeader";
import { CartHeader } from "./variants/CartHeader";
import { HomeHeader } from "./variants/HomeHeader";
import { ItemsHeader } from "./variants/ItemsHeader";
import { SearchHeader } from "./variants/SearchHeader";

export enum HeaderVariants {
  search = "search",
  cart = "cart",
  shop = "shop",
  myItems = "myItems",
  account = "account",
  home = "home",
}

export const Header = ({ variant }: { variant?: HeaderVariants }) => {
  const headerSelector = () => {
    switch (variant as any) {
      case HeaderVariants.search:
        return <SearchHeader />;
      case HeaderVariants.cart:
        return <CartHeader />;
      case HeaderVariants.myItems:
        return <ItemsHeader />;
      case HeaderVariants.account:
        return <AccountHeader />;
      case HeaderVariants.home:
        return <HomeHeader />;
      default:
        return <div>Header</div>;
    }
  };

  return headerSelector();
};
