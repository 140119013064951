import React from "react";

import styled from "@emotion/styled";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

interface IShopLocation {
  shopName: string;
  shopLocation: string;
}

export const ShopLocation = ({ shopName, shopLocation }: IShopLocation) => {
  return (
    <ComponentStyled>
      <div>
        <FontAwesomeIcon icon={faHome} />
        <span>{shopName}</span>
      </div>
      <span></span>
      <div>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        <span>{shopLocation}</span>
      </div>
    </ComponentStyled>
  );
};

const ComponentStyled = styled.div`
  display: flex;
  align-items: center;
  background: #003c79;
  color: #fff;
  padding: 14px 10px;
  font-size: 1.4rem;
  letter-spacing: 0.4px;
  text-decoration: underline;
  & > span {
    width: 2px;
    height: 13px;
    background: #fff;
    margin: 0 8px;
  }
  svg {
    margin-right: 5px;
    font-size: 1.5rem;
  }
`;
