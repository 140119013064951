import styled from "@emotion/styled";
import { actions } from "../../context/actions";
import { useLocalization } from "../../hooks/useLocalization";

const ResetFiltersButton = ({ dispatch }: { dispatch: Function }) => {
  const { translation } = useLocalization();
  return <ResetFiltersButtonStyled className="resetFilters" onClick={() => {
    // clear-filter
    dispatch({
      type: actions.REMOVE_PRODUCTS_SORTING,
    });
    dispatch({
      type: actions.REMOVE_SEARCH_FILTER,
      payload: null,
    });
  }}>{translation?.getLocalization?.Reset || 'Reset Filters'}</ResetFiltersButtonStyled>
}


const ResetFiltersButtonStyled = styled.u`
  position: absolute;
  left: 10px;
  top: 5%;
  font-size: 13px;
`;


export default ResetFiltersButton;